import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
  useToast,
  IconButton
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { FaTrash } from 'react-icons/fa'; // Assuming you want to use FontAwesome icons
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";

export default function ComplexTable() {
  const columnsData = [
    {
        Header: "CARRIER",
        accessor: "carrier",
    },
    {
        Header: "ADDRESS",
        accessor: "address",
    },
    {
        Header: "ACCOUNT USERNAME",
        accessor: "accountUsername",
    },
    {
        Header: "ACCOUNT PASSWORD",
        accessor: "accountPassword", // Assuming the accessor contains unique identifiers
    },
    {
        Header: "CREATED AT",
        accessor: "createdAt",
    },
    {
      Header: "CREATED By",
      accessor: "createdBy",
  },
    {
      Header: "ACTIONS",
      accessor: "id",
  },
];


  const toast = useToast();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
 
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };
  
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
      setSearchQuery(value);
  };
  

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/support/list-addresses`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
          },
        }
      );

      const data = response.data.data;
      setTableData(data);
      setTotalPages(response.data.totalPages);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    }finally{
      setIsDataLoading(false);
    }
  };

   // Fetch data for the initial page
  
  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForPage(currentPage, pageSize, searchQuery);
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    }
  }, [currentPage, pageSize, searchQuery]);


 
  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
    manualPagination: true,
    pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleRemove = (addressId) => {
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // Send a DELETE request to the API endpoint to remove the addressId
    axios
      .delete(`${constants.apiUrl}/support/delete-address/${addressId}`, {
        headers,
        withCredentials: true})
      .then((response) => {
        if (response.status === 200) {
          console.log("Address deleted successfully");
          toast({
            position: "bottom-right",
            title: "Address deleted successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          fetchDataForPage(currentPage, pageSize);
          // Handle success (e.g., update the UI to remove the user from the list)
        } else {
          console.error("Error deleting Address");
          toast({
            position: "bottom-right",
            title: "Error deleting Address.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          // Handle error (e.g., show an error message)
        }
      })
      .catch((error) => {
        console.error("Error deleting Address:", error);
        toast({
          position: "bottom-right",
          title: "Error deleting Address.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        // Handle errors (e.g., show an error message)
      });
  };
  

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Addresses for Label Generation
            </Text>
            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length + 1} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, cellIndex) => {
                        let data;

                        if (columns[cellIndex].Header === "ACTIONS") {
                        // Check if the user is approved
             
                data = (
                  <Flex>

                    {/* Remove button with API call */}
                    <IconButton
                      aria-label="Remove"
                      icon={<FaTrash />}
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleRemove(row.original.id)}
                      ml={2}
                    />
                  </Flex>
                );
              
                        } else {
                          data = (
                            <Text color={textColor} fontSize="sm" fontWeight="700">
                              {cell.value}
                            </Text>
                          );
                        }

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>


          </Table>
          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
