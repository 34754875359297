import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  SimpleGrid
} from "@chakra-ui/react";
import axios from "components/axios/axiosInstance";
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import { constants } from "constants/constants";

const fields = [
  { name: "System Number", key: "systemNumber", editable: false },
  { name: "3Linx Ticket Number", key: "freshdeskTicketNumber", editable: false },
  { name: "Client", key: "client", editable: false },
  { name: "Job Name", key: "jobName", editable: false },
  { name: "Job Type", key: "jobType", editable: false },
  { name: "Billable?", key: "billable", editable: false },
  { name: "Job Complete", key: "jobComplete", editable: false },
  { name: "Invoiced?", key: "invoiced", editable: false },
  { name: "Delivery Date", key: "deliveryDate", editable: false },
  { name: "Job Start Date", key: "jobStartDate", editable: false },
  { name: "Completion Date", key: "completionDate", editable: false },
  { name: "Invoice Date", key: "invoiceDate", editable: false },
  { name: "Billing Type", key: "billingType", editable: false },
  { name: "Billing Rate", key: "billingRate", editable: false },
  { name: "Set Up (Mins)", key: "setUpMins", editable: false },
  { name: "Management (Mins)", key: "managementMins", editable: false },
  { name: "Clear Down (Mins)", key: "clearDownMins", editable: false },
  { name: "# of Completed Units", key: "completedUnits", editable: false },
  { name: "Actual Second/Kit", key: "actualSecondPerKit", editable: false },
  { name: "Estimated Secs/Kit", key: "estimatedSecsPerKit", editable: false },
  { name: "Actual vs. Estimated Second/Kit Difference", key: "actualVsEstimatedDifference", editable: false },
  { name: "Material Used", key: "materialUsed", editable: false },
  { name: "Material Cost", key: "materialCost", editable: false },
  { name: "Men Required", key: "menRequired", editable: false },
  { name: "Estimated Man Hours", key: "estimatedManHours", editable: false },
  { name: "Actual Man Hours", key: "actualManHours", editable: false },
  { name: "Actual vs. Estimated Hours Difference", key: "actualVsEstimatedHoursDifference", editable: false },
  { name: "Job Cost", key: "jobCost", editable: false },
  { name: "Invoiced Amount", key: "invoicedAmount", editable: false },
];

  

function ViewJobProfit() {
  const [recordDetails, setRecordDetails] = useState({});

  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }
    
    const paramsArray = queryString.split("&");
    const params = {};
    
    paramsArray.forEach(param => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });
    
    return params;
  };
  
  const windowUrl = window.location.href;
  const queryParams = getQueryParams(windowUrl);
  const id = queryParams["id"];
  
  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(
          `${constants.apiUrl}/support/get-job-profit-details`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            withCredentials: true,
            params: {
              id,
            },
          }
        );
        
        setRecordDetails(response.data);
      } catch (error) {
        console.error("Error fetching record details:", error);
      }
    };
    
    fetchRecordDetails();
  }, [id]);


  return (
    <Box mt={14} p={4} borderWidth="1px" borderRadius="lg" shadow="md" backgroundColor={"white"}>
      <SimpleGrid columns={3} spacing={4}>
        {/* Non-editable fields */}
        {fields
          .filter((field) => !field.editable)
          .map((field) => (
            <Box key={field.key}>
              <Text fontWeight="bold">{field.name}:</Text>
              <Text>{recordDetails[field.key]}</Text>
            </Box>
          ))}
      </SimpleGrid>
     
    </Box>
  );

}

export default ViewJobProfit;
