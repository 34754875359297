import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
  useToast,
  IconButton
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { FaTrash } from 'react-icons/fa'; // Assuming you want to use FontAwesome icons
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";

export default function ComplexTable() {
  const columnsData = [
    {
      Header: "IP address",
      accessor: "ip_address",
    },
    {
      Header: "ACTIONS",
      accessor: "id",
    },
  ];

  const toast = useToast();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [newIp, setNewIp] = useState(""); // State for new IP address  

  const fetchData = async () => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const response = await axios.get(
        `${constants.apiUrl}/admin/list-whitelisted-ips`,
        {
          headers,
          withCredentials: true,
        }
      );
  
      const data = response.data;
      setTableData(data.items);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if necessary
    } finally {
      setIsDataLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []);
const data = useMemo(() => tableData, [tableData]);

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  setGlobalFilter,
  setPageSize,
  state: { globalFilter, pageIndex, pageSize },
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
} = useTable(
  {
    columns,
    data,
    initialState: {
        pageIndex: 0,
        pageSize: 25,
      },
  },
  useGlobalFilter,
  useSortBy,
  usePagination
);

useEffect(() => {
  setPageSize(pageSize);
}, [pageSize, setPageSize]);

const handlePageChange = (newPage) => {
  setCurrentPage(newPage + 1);
};

const handlePageSizeChange = (newSize) => {
  setPageSize(newSize);
  setCurrentPage(1);
};

const handleSearchChange = (e) => {
  const value = e.target.value || undefined;
  setSearchQuery(value);
  setGlobalFilter(value);
  setCurrentPage(1);
};
  

  const handleRemove = (id) => {
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .delete(`${constants.apiUrl}/admin/whitelisted-ip/${id}`, {
        headers,
        withCredentials: true
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("IP Address deleted successfully");
          toast({
            position: "bottom-right",
            title: "IP Address deleted successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          fetchData();
        } else {
          console.error("Error deleting IP Address");
          toast({
            position: "bottom-right",
            title: "Error deleting IP Address.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error deleting IP Address:", error);
        toast({
          position: "bottom-right",
          title: "Error deleting IP Address.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleAddIp = async () => {
    if (!newIp) {
      toast({
        position: "bottom-right",
        title: "Please enter an IP address.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${constants.apiUrl}/admin/add-whitelisted-ip`,
        { ip_address: newIp },
        { headers, withCredentials: true }
      );

      if (response.status === 200) {
        console.log("IP Address added successfully");
        toast({
          position: "bottom-right",
          title: "IP Address added successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setNewIp(""); // Clear the input field
        fetchData(); // Refresh the table data
      } else {
        console.error("Error adding IP Address");
        toast({
          position: "bottom-right",
          title: "Error adding IP Address.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error adding IP Address:", error);
      toast({
        position: "bottom-right",
        title: "Error adding IP Address.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Whitelist IP Addresses for Variable Shipping
            </Text>
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange} // Ensure this is the correct function name
              value={searchQuery}
              maxWidth="200px"
            />

          </Flex>
          <Flex px="25px" mb="20px" align="center" w="100%" maxW="1200px" justify="center" mx="auto">
            <Input
              type="text"
              placeholder="Enter IP address"
              value={newIp}
              onChange={(e) => setNewIp(e.target.value)}
              maxWidth="500px"
              mr="10px"
            />
            <Button colorScheme="teal" onClick={handleAddIp}>
              Add IP
            </Button>
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr                 {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, idx) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={idx}
                    borderColor={borderColor}
                    color={textColor}
                    fontSize="14px"
                    fontWeight="700"
                    px="10px"
                    textAlign={column.Header === "ACTIONS" ? "center" : "left"}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon as={FiArrowDown} ml="2" />
                      ) : (
                        <Icon as={FiArrowUp} ml="2" />
                      )
                    ) : (
                      ""
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => (
                    <Td
                      {...cell.getCellProps()}
                      borderColor={borderColor}
                      color={textColor}
                      fontSize="14px"
                      fontWeight="400"
                      key={index}
                      textAlign={cell.column.Header === "ACTIONS" ? "center" : "left"}
                    >
                      {cell.column.Header === "ACTIONS" ? (
                        <>
                          <IconButton
                            aria-label="Delete"
                            icon={<FaTrash />}
                            colorScheme="red"
                            onClick={() => handleRemove(row.original.id)}
                          />
                        </>
                      ) : (
                        cell.render("Cell")
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Flex direction="row" justify="space-between" align="center" px="25px" mb="20px">
  <Text color={textColor} fontSize="14px" fontWeight="400">
    Showing {page.length} of {data.length} results
  </Text>
  <Flex align="center">
    <Button
      onClick={() => previousPage()}
      isDisabled={!canPreviousPage}
      colorScheme="blue"
      mr="4px"
    >
      Previous
    </Button>
    <Button
      onClick={() => nextPage()}
      isDisabled={!canNextPage}
      colorScheme="blue"
      ml="4px"
    >
      Next
    </Button>
  </Flex>
</Flex>

      </Flex>
    )}
  </Card>
);
}

