import React, { useState } from "react";
import axios from "components/axios/axiosInstance";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  Select,
  SimpleGrid,
  useToast,
  Link as ChakraLink
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import ItemTable from "./components/ItemTable";
import DamagedForm from "./components/DamagedForm";
import DelayForm from "./components/LateForm";
function Claims() {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [claimType, setClaimType] = useState("");
  const toast = useToast();
  const LostForm = ({ orderItems, trackingNumber }) => {
    return (
      <ItemTable orderItems={orderItems} trackingNumber={trackingNumber} />
    );
  };

  const DamageForm = ({ orderItems, trackingNumber }) => {
    return (
      <DamagedForm orderItems={orderItems} trackingNumber={trackingNumber} />
    );
  };

  const LateForm = ({ orderItems, trackingNumber }) => {
  
    return (
      <DelayForm orderItems={orderItems} trackingNumber={trackingNumber}/>
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    
    // Call the API with the provided order number
    axios
      .get(`${constants.apiUrl}/customer/get-claims`, { headers, withCredentials: true, params: { trackingNumber: trackingNumber, userId: userDetails.userId } })
      .then((response) => {
        const responseData = response.data;
        const despatchDate = responseData.DespatchDate;
        const options = {
          timeZone: 'Europe/London',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        };
        
        const formattedDespatchDate = new Intl.DateTimeFormat('en-GB', options).format(new Date(despatchDate));
        
        responseData.DespatchDate = formattedDespatchDate; // Update the formatted date in the response data
        
        setClaimType("");
        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        toast({
          position: "top",
          title: "Error",
          description: error.response.data.message || "An error occurred while fetching claims.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setClaimType("");
        setIsLoading(false);
        setResponseData(null);
      });
  };
  

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex direction={{ base: "column", md: "row" }} gridGap="20px">

        <FormControl mt={6} >
          <FormLabel textAlign={"end"}>Enter Tracking Number</FormLabel>

        </FormControl>
        <FormControl mt={4}>
          <Input
            type="text"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            required
            variant="brand"
          />
        </FormControl>
        {/* Add more dropdowns as needed for warehouse, department, client, task type, and billing type */}
        <FormControl mt={4}>
          <Button variant="brand" size='md' onClick={handleFormSubmit}>
            Report Lost/Damaged
          </Button>

        </FormControl>

      </Flex>
      {isLoading ? (
        <Flex mt={8} alignItems="center" justifyContent="center">
          <Spinner size="xl" color="green.500" />
        </Flex>
      ) : responseData ? (
        <Box p={6} mt={8} backgroundColor={"white"} >
          <SimpleGrid columns={4} spacing={4}>
            <Box>
              <Text fontWeight="bold" mr={2}>
                Carrier:
              </Text>
              <Text>{responseData.carrierName}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold" mr={2}>
                Order number:
              </Text>
              <Text>{responseData.OrderNumber}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold" mr={2}>
                Despatch Date:
              </Text>
              <Text>{responseData.DespatchDate}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold" mr={2}>
                Claim type:
              </Text>
              <Select
                value={claimType}
                onChange={(e) => {
                
                  // Check if the selected option is 'lost' and show the toast
                  if (e.target.value === 'lost' && !responseData.isAllowedLost) {
                    toast({
                      position: "top",
                      title: 'Error',
                      description: (
                        <>
                          The carrier requests a minimum period before the claim can be made. Please try again at a later date.  For waiting period by carrier click list
                          {" "}
                          <ChakraLink  href="/#/customer/claims-sla" color="blue.500" textDecoration="underline">
                            here
                          </ChakraLink>
                          .
                        </>
                      ),
                      status: 'error',
                      duration: 9000, // Adjust the duration as needed
                      isClosable: true,
                    })
                    return null;
                  }else if(e.target.value === 'damage' && !responseData.isAllowedDamaged) {
                    toast({
                      position: "top",
                      title: 'Error',
                      description: (
                        <>
                          The carrier requests a minimum period before the claim can be made. Please try again at a later date.  For waiting period by carrier click list
                          {" "}
                          <ChakraLink  href="/#/customer/claims-sla" color="blue.500" textDecoration="underline">
                            here
                          </ChakraLink>
                          .
                        </>
                      ),
                      status: 'error',
                      duration: 9000, // Adjust the duration as needed
                      isClosable: true,
                    })
                    return null;
                  }else{
                    setClaimType(e.target.value);
                  }
                }}
                placeholder="Select claim type"
              >
                <option value="lost">Lost</option>
                <option value="damage">Damage</option>
                {/* <option value="late">Late</option> */}
              </Select>
            </Box>
          </SimpleGrid>

        </Box>
      ) : null}

   
      {claimType === "lost" && !isLoading && <LostForm orderItems={responseData?.OrderItems || []} trackingNumber={trackingNumber} />}
      {claimType === "damage" && !isLoading && <DamageForm orderItems={responseData?.OrderItems || []} trackingNumber={trackingNumber} isAllowedDamaged={responseData.isAllowedDamaged } />}
      {claimType === "late" && !isLoading && <LateForm orderItems={responseData?.OrderItems || []} trackingNumber={trackingNumber} />}
    </Box>

  );



}

export default Claims;
