import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
  Box,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";
const InputBox = ({ title, value }) => {
  return (
    <InputGroup size="sm" width="100%">
     
      <Input
        w={"85px"}
        type="text"
        value={value}
        readOnly
        pointerEvents="none"
        bg="white"
        border="1px solid #ccc"
        borderRadius="sm"
        _hover={{ bg: "#f0f0f0" }}
      />
       <InputRightAddon children={title} bg="gray.100" />
    </InputGroup>
  );
};
export default function ComplexTable() {
  const columnsData = [
    {
      Header: "Client",
      accessor: "ClientName", // Assuming the accessor contains unique identifiers
    },
    {
      Header: "SKU",
      accessor: "SKU",
    },
    {
      Header: "EAN Barcode",
      accessor: "EANBarcode",
    },
    {
      Header: "Qty",
      accessor: "StockLevel",
    },
    {
      Header: "Dimensions & Weight", // Grouped header for dimensions and weight
      accessor: "DimensionsAndWeight",
      Cell: ({ row }) => (
        <Flex direction="row">
        <InputBox title="KG" value={`${row.original.Weight}`} />
        <Box ml={2} /> {/* Add space */}
        <InputBox title="L" value={`${row.original.Length}`} />
        <Box ml={2} /> {/* Add space */}
        <InputBox title="D" value={`${row.original.Depth}`} />
        <Box ml={2} /> {/* Add space */}
        <InputBox title="H" value={`${row.original.Height}`} />
        <Box ml={2} /> {/* Add space */}
        <InputBox title="V" value={`${row.original.VolumetricSize}`} />
      </Flex>
      ),
    },
    {
      Header: "Updated By",
      accessor: "updatedBy",
    },
    {
      Header: "Date Captured",
      accessor: "createdAt",
    },
    {
      Header: "Date Updated",
      accessor: "dateDimsManuallyUpdated",
    },
    
  ];

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [clientFilter, setClientFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [selectedClient, setSelectedClient] = useState(''); // Add this state
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [capturedCount, setCapturedCount] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };
  
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
      setSearchQuery(value);
  };
  

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/support/product-captured-dims-by-sku`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
            client: selectedClient
          },
        }
      );

      const data = response.data.users;
      setTableData(data);
      setTotalPages(response.data.totalPages);
      setCapturedCount(response.data.capturedCount);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching dims Sku data:", error);
      // Handle error if necessary
    }finally{
      setIsDataLoading(false);
    }
  };

  const fetchFilterData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${constants.apiUrl}/support/client`, {
        headers,
        withCredentials: true,
      });

      const responseArr = response.data;
      const clientIds = responseArr.map(obj => obj.client_id); // Extract client_id values
      setClientFilter(clientIds);
    

    } catch (error) {
      console.error("Error fetching filters:", error);

    }
  };

   // Fetch data for the initial page
  
  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForPage(currentPage, pageSize, searchQuery);
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    }
  }, [currentPage, pageSize, searchQuery, selectedClient]);

  useEffect(() => {
    fetchFilterData();
  }, []);

 
  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
    manualPagination: true,
    pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
<>

<Flex px="25px" justify="space-between" ml="auto" mr="auto" mb={"10px"} align="center">

      <Text
        color={textColor}
        fontSize="22px"
        fontWeight="700"
        lineHeight="100%"
      >
        Report By SKU - {selectedClient ? selectedClient : "All"} | Captured SKUs {capturedCount}
      </Text>
 
    </Flex>
    </>
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

        {/* Dropdown for Clients */}
        <Select
          ml={2}
          value={selectedClient}
          flex="2"
          onChange={(e) => {
            setSelectedClient(e.target.value);
          }}
        >
          <option value="">All Clients</option>
          {clientFilter.map((client) => (
            <option key={client} value={client}>
              {client}
            </option>
          ))}
        </Select>

           
            {/* Search Input */}
            <Input
              ml={2}
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
          </Flex>

          <Box overflowX="auto" maxWidth="100%">
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                      minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                      whiteSpace="normal"
                      wordBreak="break-word"
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length + 1} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, cellIndex) => {
                        let data;

                        // Render the grouped dimensions and weight in a single cell
                      if (cell.column.id === "DimensionsAndWeight") {
                        data = cell.render("Cell");
                      } else {
                        data = (
                          <Text color={textColor} fontSize="sm" fontWeight="700">
                            {cell.value}
                          </Text>
                        );
                      }
                                  

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>


          </Table>
          </Box>
          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
