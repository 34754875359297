import React, { useEffect, useState } from "react";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Icon,
} from "@chakra-ui/react";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
import DatePicker from "react-datepicker";
import { FiDownload } from "react-icons/fi";
import Card from "components/card/Card";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css"; // Import your custom CSS for DatePicker
import { format } from "date-fns";

export default function ProjectionTable() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [filters, setFilters] = useState({
    warehouse: "",
    client: "",
    startDate: null,
    endDate: null,
    type: "Actual",
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    warehouseOptions: [],
    clientOptions: [],
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/support/projection-reports`,
        {
          headers,
          params: {
            warehouse: filters.warehouse,
            client: filters.client,
            startDate: filters.startDate,
            endDate: filters.endDate,
            type: filters.type,
          },
        }
      );

      const { INBOUND, OUTBOUND, TASKS, RETURNS } = response.data;
      const allDates = [
        ...new Set([
          ...INBOUND.map((item) => item.Date),
          ...OUTBOUND.map((item) => item.Date),
          ...TASKS.map((item) => item.Date),
          ...RETURNS.map((item) => item.Date),
        ]),
      ].sort();

      setData({ INBOUND, OUTBOUND, TASKS, RETURNS });
      setDates(allDates);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    // Fetch all dropdown options
    // Assuming you have an API endpoint to submit product data
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${constants.apiUrl}/support/qc-dropdowns`, { headers })
      .then((response) => {
        const { warehouseOptions, clientOptions } = response.data;
        setDropdownOptions({
          warehouseOptions: warehouseOptions.length > 0 ? warehouseOptions : [],
          clientOptions: clientOptions.length > 0 ? clientOptions : [],
        });
      })
      .catch((error) =>
        console.error("Error fetching dropdown options:", error)
      );
  }, []);

  const handleFilterChange = (key, value) => {
    let formattedValue = value;

    if (key === "startDate" || key === "endDate") {
      formattedValue = value ? format(value, "yyyy-MM-dd") : null;
    }

    setFilters((prev) => ({ ...prev, [key]: formattedValue }));
  };

  const renderRow = (type) => {
    return dates.map((date) => {
      const item = data[type].find((entry) => entry.Date === date);
      return (
        <Td key={date} textAlign="center">
          {item ? item.total : 0}
        </Td>
      );
    });
  };

  const formatDateStringForCSV = (dateString) => {
    const date = new Date(dateString);
    return `'${format(date, "dd/MM/yyyy")}`;
  };
  
  const convertToCSV = () => {
    let csvContent = "";
  
    // Add warehouse and client information
    csvContent += `WAREHOUSE,${filters.warehouse ? filters.warehouse : "ALL"}\n`;
    csvContent += `CLIENT,${filters.client ? filters.client : "ALL"}\n\n`;
  
    // Add the header row with formatted dates
    csvContent += "Type," + dates.map((date) => formatDateStringForCSV(date)).join(",") + "\n";
  
    // Add the data rows
    const types = ["INBOUND", "OUTBOUND", "TASKS", "RETURNS"];
    types.forEach((type) => {
      csvContent += type + ",";
      csvContent += dates.map((date) => {
        const item = data[type].find((entry) => entry.Date === date);
        return item ? item.total : 0;
      }).join(",");
      csvContent += "\n";
    });
  
    return csvContent;
  };
  
  const handleExportButtonClick = () => {
    const csvContent = convertToCSV();
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    
    // Generate the file name
    const type = filters.type ? filters.type : "ALL";
    const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
    const fileName = `projection_report_${type}_${timestamp}.csv`;
    
    // Create a download link
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", fileName);
    
    // Append link to the body and trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Revoke the URL to free up resources
    URL.revokeObjectURL(url);
  };


  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy");
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex direction="column" w="100%" px="0px" overflowX="auto">
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Select
            placeholder="All Warehouse"
            onChange={(e) => handleFilterChange("warehouse", e.target.value)}
            mr="10px" // Add margin to create space between filters
            mb="10px" // Add margin to create space between rows
            value={filters.warehouse}
          >
            {dropdownOptions.warehouseOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Select
            placeholder="All Client"
            onChange={(e) => handleFilterChange("client", e.target.value)}
            mr="10px" // Add margin to create space between filters
            mb="10px" // Add margin to create space between rows
            value={filters.client}
          >
            {dropdownOptions.clientOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Select
            onChange={(e) => handleFilterChange("type", e.target.value)}
            mr="10px" // Add margin to create space between filters
            mb="10px" // Add margin to create space between rows
            value={filters.type}
          >
             <option key={"Actual"} value={"Actual"}>
             {"Actual"}
            </option>
            <option key={"Esitmated"} value={"Estimated"}>
             {"Estimated"}
            </option>
            <option key={"EstimateConfirmed"} value={"EstimateConfirmed"}>
             {"Esitmate Confirmed"}
            </option>
           
          </Select>
          <DatePicker
            selected={filters.startDate ? new Date(filters.startDate) : null}
            onChange={(date) => handleFilterChange("startDate", date)}
            placeholderText="Start Date"
            dateFormat="yyyy-MM-dd"
            className="custom-datepicker"
          />
          <DatePicker
            selected={filters.endDate ? new Date(filters.endDate) : null}
            onChange={(date) => handleFilterChange("endDate", date)}
            placeholderText="End Date"
            dateFormat="yyyy-MM-dd"
            className="custom-datepicker"
          />
           <Icon
              as={FiDownload}
              onClick={handleExportButtonClick}
              variant="brand"
              w={"4em"}
              h={"1.5em"}
            
            >
              Export
            </Icon>
        </Flex>

        {isLoading ? (
          <Flex height="100vh" alignItems="center" justifyContent="center">
            <Spinner size="xl" thickness="4px" color="green.500" />
          </Flex>
        ) : (
          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              <Tr>
                <Th>Type</Th>
                {dates.map((date) => (
                  <Th key={date} textAlign="center">
                    {formatDateString(date)}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td><b>INBOUND</b> <span>(units)</span></Td>
      
                {renderRow("INBOUND")}
              </Tr>
              <Tr>
                <Td><b>OUTBOUND</b> <span>(orders)</span></Td>
                {renderRow("OUTBOUND")}
              </Tr>
              <Tr>
                <Td><b>TASKS</b> <span>(units)</span></Td>
                {renderRow("TASKS")}
              </Tr>
              <Tr>
                <Td><b>RETURNS</b> <span>(units)</span></Td>
                {renderRow("RETURNS")}
              </Tr>
            </Tbody>
          </Table>
        )}
      </Flex>
    </Card>
  );
}
