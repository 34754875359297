import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, Icon, useColorModeValue } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

export function SidebarLinks(props) {
  const { routes } = props;
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  const activeIcon = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const [openCategory, setOpenCategory] = useState("");
  const [activeSubItemPath, setActiveSubItemPath] = useState(""); // Keep track of the active sub-item path

  useEffect(() => {
    const matchingSubItem = routes.find(
      (route) => route.path && location.pathname.includes(route.path)
    );
  
    if (matchingSubItem) {
      const matchingCategory = findOpenCategory(matchingSubItem.layout + matchingSubItem.path);
      
      // Check if the matching category is already open before updating
      if (matchingCategory !== openCategory) {
        setOpenCategory(matchingCategory);
      }
      
      setActiveSubItemPath(matchingSubItem.layout + matchingSubItem.path);
    }
  }, [location.pathname, routes]); // Add openCategory to the dependency array
  
  
  const findOpenCategory = (subItemPath) => {
    for (const route of routes) {
      if (route.category && route.items.some(item => subItemPath.includes(item.path))) {
        return route.name;
      }
    }
    return "";
  };

  const handleCategoryClick = (categoryName) => {
    setOpenCategory(categoryName === openCategory ? "" : categoryName);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // Function to filter routes based on user roles
// Function to filter routes based on user roles and exclude duplicates with different layouts
const filterRoutes = (routes, userRoles) => {
  // Split userRoles string into an array of roles
  const rolesArray = userRoles.map(role => role.trim());
  
  // Track seen categories and paths to exclude duplicates with different layouts
  const seenRoutes = {};

  // Filter routes based on accessRole and exclude duplicates
  const filteredRoutes = routes.filter(route => {
    // If route has no accessRole defined, include it by default
    if (!route.accessRole) return true;

    // Split accessRole string into an array of roles
    const routeRoles = route.accessRole.split(',').map(role => role.trim());

    // Check if any of the user's roles match the route's roles
    const roleMatch = routeRoles.some(role => rolesArray.includes(role));

    // If the route is a category and path that has been seen with a different layout, exclude it
    const key = `${route.category}-${route.path}`;
    if (route.category && route.path && seenRoutes[key] && seenRoutes[key] !== route.layout) {
      return false;
    }

    // Add current route to seenRoutes
    if (route.category && route.path) {
      seenRoutes[key] = route.layout;
    }

    return roleMatch;
  });

  return filteredRoutes;
};



  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <Box
          key={index}
          _hover={{
            cursor: "pointer" // Show hand icon on hover
          }}
        
        >
          <Flex alignItems="center" justifyContent="space-between"  _hover={{
            backgroundColor: "gray.200"
          }}>
            <Flex
              alignItems="center"
              onClick={() => handleCategoryClick(route.name)}
            >
              <Box
                color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                me='18px'
              >
                {route.icon}
              </Box>
              <Text
                fontSize="md"
                color={openCategory === route.name ? activeColor : textColor}
                fontWeight={openCategory === route.name ? "bold" : "normal"}
                py="8px"
              >
                {route.name}
              </Text>
            </Flex>
            <Icon
              as={openCategory === route.name ? ChevronUpIcon : ChevronDownIcon}
              color={activeColor}
              w={"2em"}
              h={"23px"}
              onClick={() => handleCategoryClick(route.name)}
            />
          </Flex>
          {openCategory === route.name && createLinks(route.items)}
        </Box>
        
        );
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/customer" ||
        route.layout === "/support" ||
        route.layout === "/operations" ||
        route.layout === "/generic"
      ) {
        return (
    
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box  _hover={{
                backgroundColor: "gray.200"
              }}>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  >
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='22px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box  _hover={{
                backgroundColor: "gray.200"
              }}>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                >
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='22px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      } else if (route.external) {
        return (
          <Box key={index}>
            <Flex alignItems="center" _hover={{
            backgroundColor: "gray.200"
          }}>
              <a
                href={route.path}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  color={textColor}
                  me="18px"
                 
                >
                  {route.icon}
                </Box>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="normal"
                  py="4px"
                >
                  {route.name}
                </Text>
              </a>
            </Flex>
          </Box>
        );
      }
    });
  };
  
  const userRoles = ["Support","Admin","Customer","Operations"];
  //  BRAND
  return createLinks(filterRoutes(routes, userRoles));
}

export default SidebarLinks;
