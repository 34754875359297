import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Box,
    Spinner,
    Input,
    Text,
    Button,
    useToast,
    Select,
    Textarea,
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import Card from "components/card/Card";

export default function QCDataCapture() {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        qcType: null,
        warehouse: null,
        client: null,
        orderReference: "",
        comments: "",
        status: null,
    });

    const [qcOptions, setQCOptions] = useState({
        qcTypeOptions: [],
        warehouseOptions: [],
        clientOptions: [],
        statusOptions: []
    })

    const toast = useToast();

    // Fetch options for dropdowns
    useEffect(() => {
        // Fetch all dropdown options
        // Assuming you have an API endpoint to submit product data
        const token = localStorage.getItem("accessToken");
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        axios.get(`${constants.apiUrl}/support/qc-dropdowns`, { headers })
            .then(response => {
                const { qcTypeOptions, warehouseOptions, clientOptions, statusOptions } = response.data;
                setQCOptions({
                    qcTypeOptions: qcTypeOptions.length > 0 ? qcTypeOptions : [], // Set default value for qcType
                    warehouseOptions: warehouseOptions.length > 0 ? warehouseOptions : [], // Set default value for warehouse
                    clientOptions: clientOptions.length > 0 ? clientOptions : [], // Set default value for client
                    statusOptions: statusOptions.length > 0 ? statusOptions : [], // Set default value for status
                });

                setFormData({
                    qcType: qcTypeOptions.length > 0 ? qcTypeOptions[0] : "", // Set default value for qcType
                    warehouse: warehouseOptions.length > 0 ? warehouseOptions[0] : "", // Set default value for warehouse
                    client: clientOptions.length > 0 ? clientOptions[0] : "", // Set default value for client
                    status: statusOptions.length > 0 ? statusOptions[0] : "", // Set default value for status
                });

            })
            .catch(error => console.error("Error fetching dropdown options:", error));
    }, []);


    const handleInputChange = (e, key) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    const handleSubmit = () => {
        // Check if any required fields are empty
        if (!formData.qcType || !formData.warehouse || !formData.status || !formData.orderReference) {
            // Display an error message
            toast({
                position: 'bottom-right',
                title: "Please fill in all required fields.",
                status: "error",
                duration: 3000, // Duration in milliseconds
                isClosable: true, // Whether the toast can be closed by the user
            });
            return; // Stop further execution
        }
        // Set loading state to true
        setIsLoading(true);

        // Assuming you have an API endpoint to submit product data
        const token = localStorage.getItem("accessToken");
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        if(formData.qcType === "Courier Trolley"){
            formData.client = "";
        }
        
        axios
            .post(`${constants.apiUrl}/support/submit-qc-data`, formData, {
                headers,
                withCredentials: true,
            })
            .then((response) => {
                // Clear loading state
                setIsLoading(false);

                // Handle success
                toast({
                    position: 'bottom-right',
                    title: 'QC Data Created Successfully.',
                    status: "success",
                    duration: 3000, // Duration in milliseconds
                    isClosable: true, // Whether the toast can be closed by the user
                });
                console.log("QC data submitted:", response.data);

                // Reset form data to empty values
                setFormData({
                    qcType: qcOptions.qcTypeOptions[0],
                    warehouse: qcOptions.warehouseOptions[0],
                    client: qcOptions.clientOptions[0],
                    orderReference: "",
                    comments: "",
                    status: qcOptions.statusOptions[0],
                });
            })
            .catch((error) => {
                // Clear loading state
                setIsLoading(false);

                // Handle error
                toast({
                    position: 'bottom-right',
                    title: "Error creating QC Data" + error,
                    status: "error",
                    duration: 3000, // Duration in milliseconds
                    isClosable: true, // Whether the toast can be closed by the user
                });
                console.error("Error submitting QC data:", error);
            });
    };

    return (

        <Card mt={4} p={4} backgroundColor="white">

            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <Box>
                    <Text fontWeight="bold">QC Type:</Text>
                    <Select

                        value={formData.qcType}
                        onChange={(e) => handleInputChange(e, "qcType")}
                    >
                        {qcOptions.qcTypeOptions !== null && qcOptions.qcTypeOptions.map((option, index) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Select>
                </Box>
                <Box mt={4}>
                    <Text fontWeight="bold">Warehouse:</Text>
                    <Select

                        value={formData.warehouse}
                        onChange={(e) => handleInputChange(e, "warehouse")}
                    >
                        {qcOptions.warehouseOptions && qcOptions.warehouseOptions.map((option, index) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Select>
                </Box>
                {formData.qcType !== 'Courier Trolley' && (
                    <Box mt={4}>
                        <Text fontWeight="bold">Client:</Text>
                        <Select
                            value={formData.client}
                            onChange={(e) => handleInputChange(e, "client")}
                        >
                            {qcOptions.clientOptions && qcOptions.clientOptions.map((option, index) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                    </Box>
                )}


                <Box mt={4}>
                    <Text fontWeight="bold">Order/Reference No. :</Text>
                    <Input
                        placeholder="Order Reference"
                        value={formData.orderReference}
                        onChange={(e) => handleInputChange(e, "orderReference")}
                    />
                </Box>

                <Box mt={4}>
                    <Text fontWeight="bold">Status:</Text>
                    <Select

                        value={formData.status}
                        onChange={(e) => handleInputChange(e, "status")}
                    >
                        {qcOptions.statusOptions && qcOptions.statusOptions.map((option, index) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Select>
                </Box>

                <Box mt={4}>
                    <Text fontWeight="bold">Comments:</Text>
                    <Textarea
                        placeholder="Comments"
                        value={formData.comments}
                        onChange={(e) => handleInputChange(e, "comments")}
                    />
                </Box>
                <Button
                    display={"flex"}
                    colorScheme="teal"
                    variant="solid"
                    mt={4}
                    onClick={handleSubmit}
                    isLoading={isLoading} // Enable loading state
                    loadingText="Submitting" // Optional loading text
                    ml={"auto"}
                    mr={"auto"}
                >
                    {isLoading ? "Submitting" : "Submit"}
                    {isLoading && <Spinner size="sm" ml={2} />}
                </Button>
            </Box>
        </Card>
    );

}
