import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
import { Link, useLocation } from "react-router-dom";
import ComplexTable from "views/support/jobInvoice/components/ComplexTable";
function JobInvoice() {
  const [data, setData] = useState([]);
  const cardBgColor = useColorModeValue("white", "gray.700");
  const location = useLocation();
  const queryParamsExist = new URLSearchParams(location.search).toString() !== "";

  const columnsDataComplex = [
    {
        Header: "3LINX TICKET NUMBER",
        accessor: "freshdeskTicketNumber",
    },
    {
        Header: "CREATION DATE",
        accessor: "creationDate",
    },
    {
        Header: "CLIENT",
        accessor: "client",
    },
    {
        Header: "TASK TYPE",
        accessor: "taskType",
    },
    {
        Header: "TASK NAME",
        accessor: "taskName",
    },
    {
      Header: "NUMBER OF UNITS",
      accessor: "numberOfUnits",
    },
    {
      Header: "TASK PRIORITY",
      accessor: "taskPriority",
  },
    {
      Header: "DELIVERY DATE",
      accessor: "deliveryDate",
  },
  
];

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // Make the API call to fetch the data
    axios.get(`${constants.apiUrl}/support/invoices-warehouse-clients`, { headers, withCredentials: true })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {queryParamsExist ? (
        <Box p={2}>
          <SimpleGrid gap="20px" mb="20px">
            <ComplexTable columnsData={columnsDataComplex} />
          </SimpleGrid>
        </Box>
      ) : (
        <>
          {data.map((item, index) => (
            <Box
              key={index}
              bg={cardBgColor}
              boxShadow="lg"
              borderRadius="md"
              p={4}
              mb={4}
              display="flex"
              flexDirection="column"
            >
              <Link
                to={`/support/job-invoice?warehouse=${encodeURIComponent(
                  item.warehouseName
                )}`}
              >
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  {item.warehouseName}
                </Text>
              </Link>
              <Flex justifyContent="space-between">
                {item.clients.map((client, i) => (
                  <Box
                    key={i}
                    textAlign="center"
                    w="50%"
                    p={2}
                    borderRadius="md"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Link
                      to={`/support/job-invoice?warehouse=${encodeURIComponent(
                        item.warehouseName
                      )}&client=${encodeURIComponent(client.name)}`}
                    >
                      <Text
                        fontSize="md"
                        fontWeight="bold"
                        variant="brand"
                        p={1}
                        _hover={{
                          bg: "green.500",
                          color: "white",
                          cursor: "pointer",
                          p: "1",
                        }}
                      >
                        {client.name}
                      </Text>
                    </Link>
                    <Text fontSize="sm" color="gray.600">
                      <Text as="span" fontWeight="bold" variant="brand">
                        {client.count}
                      </Text>{" "}
                      Job
                    </Text>
                   
                  </Box>
                ))}
              </Flex>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
  
  
  
  
  
}

export default JobInvoice;
