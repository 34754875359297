// PrivateRoute.js
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { constants } from 'constants/constants';
import { useHistory, useLocation } from 'react-router-dom';

const PrivateRoute = ({ component: Component, requiredRoles, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState([]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    axios
      .post(`${constants.apiUrl}/validate-token`, { token }, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          const roleArr = response.data.role.split(",");
          setUserRole(roleArr);
          setIsAuthenticated(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('accessToken');
          history.push('/auth/sign-in');
        }
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, [location]);

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    );
  }

  // Ensure requiredRoles is always treated as an array

  const roles = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles];
  // Check if the user is authenticated and has one of the required roles
  const isAuthorized = isAuthenticated && roles.some((role) => userRole.includes(role));



  if (!isAuthorized) {
    return <Redirect to="/auth/sign-in" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (isAuthorized ? <Component {...props} /> : <Redirect to="/auth/sign-in" /> )}
    />
  );
};

export default PrivateRoute;
