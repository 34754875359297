import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
  Box,
  InputGroup,
  InputRightAddon,
  Checkbox,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp, FiDownload } from "react-icons/fi";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";
const InputBox = ({ title, value, onChange, isReadOnly }) => {
  const [inputValue, setInputValue] = useState(value);
 
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    onChange(e.target.value);
    setInputValue(e.target.value);
  };

  return (
    <InputGroup size="sm" width="100%">
      <Input
        w={"80%"}
        type="text"
        value={inputValue}
        onChange={handleChange}
        bg="white"
        border="1px solid #ccc"
        borderRadius="sm"
        isReadOnly={isReadOnly}
      />
      <InputRightAddon children={title} bg="gray.100" />
    </InputGroup>
  );
};
export default function ComplexTable() {
  const columnsData = [
    {
      Header: "Client",
      accessor: "ClientName", // Assuming the accessor contains unique identifiers
    },
    {
      Header: "SKU",
      accessor: "SKU",
    },
    {
      Header: "EAN Barcode",
      accessor: "EANBarcode",
    },
    {
      Header: "Qty",
      accessor: "StockLevel",
    },
    {
      Header: "Dimensions & Weight",
      accessor: "DimensionsAndWeight",
      Cell: ({ row }) => (
        <Flex direction="row">
          <InputBox
            title="KG"
            value={row.original.Weight}
            onChange={(value) =>
              handleDimensionsWeightChange(row.original, "Weight", value)
            }
          />
          <Box ml={2} />
          <InputBox
            title="L"
            value={row.original.Length}
            onChange={(value) =>
              handleDimensionsWeightChange(row.original, "Length", value)
            }
          />
          <Box ml={2} />
          <InputBox
            title="D"
            value={row.original.Depth}
            onChange={(value) =>
              handleDimensionsWeightChange(row.original, "Depth", value)
            }
          />
          <Box ml={2} />
          <InputBox
            title="H"
            value={row.original.Height}
            onChange={(value) =>
              handleDimensionsWeightChange(row.original, "Height", value)
            }
          />
          <Box ml={2} />
          <InputBox
            title="V"
            value={row.original.VolumetricSize}
            isReadOnly
          />
        </Flex>
      ),
    },
  ];


  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [clientFilter, setClientFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [selectedClient, setSelectedClient] = useState(""); // Add this state
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [uncapturedCount, setUncapturedCount] = useState(0);
  const [selectedSKUs, setSelectedSKUs] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  

  function calculateVolumetricSize(length, depth, height) {
    // Check if length, depth, and height are present and not 0
    if (length && depth && height && length !== '0' && depth !== '0' && height !== '0') {
      // Calculate VolumetricSize
      return (length * depth * height).toFixed(2);
    } else {
      // Return '0.00' if length, depth, or height are missing, not valid numbers, or '0'
      return '0.00';
    }
  }
  
  const handleDimensionsWeightChange = (row, field, value) => {
    setTableData((prevData) =>
      prevData.map((item) => {
        if (item.uniqueID === row.uniqueID) {
          const updatedItem = { ...item, [field]: value };
  
          // Calculate new VolumetricSize
          const { Length, Depth, Height } = updatedItem;
          
          updatedItem.VolumetricSize = calculateVolumetricSize(Length, Depth, Height);

          return updatedItem;
        }
        return item;
      })
    );
  };
  
  
  


  const handleCheckboxChange = (e, row) => {
    if (e.target.checked) {
      setSelectedSKUs([...selectedSKUs, row]);
    } else {
      setSelectedSKUs(selectedSKUs.filter((selectedRow) => selectedRow.SKU !== row.SKU));
    }
  };
  
  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedSKUs(page.map((row) => row.original));
    } else {
      setSelectedSKUs([]);
    }
  };

  const handleBulkAction = () => {
    // Confirmation dialog open
    onOpen();
  };

  const handleConfirmBulkAction = async () => {
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await axios.post(
        `${constants.apiUrl}/support/mark-captured-dims-sku`,
        { products: selectedSKUs }, // Send selected SKUs in the request body
        { headers, withCredentials: true } // Pass headers and credentials as configuration
      );
  
      const data = response.data;
      console.log(data);
      fetchDataForPage(currentPage, pageSize);
      
      onClose();
    } catch (error) {
      console.error("Error marking SKUs as captured:", error);
      // Handle error appropriately
    }
  };
  

  const dateOptions = [
    { value: "1", label: "Last 1 day" },
    { value: "7", label: "Last 7 days" },
    { value: "30", label: "Last 30 days" },
    { value: "90", label: "Last 90 days" },
    { value: "180", label: "Last 180 days" },
  ];

  const [selectedOption, setSelectedOption] = useState("");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/support/product-dims-by-sku`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
            client: selectedClient,
            days: selectedOption,
          },
        }
      );

      const data = response.data.users;
      setTableData(data);
      setTotalPages(response.data.totalPages);
      setUncapturedCount(response.data.uncapturedCount);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching dims Sku data:", error);
      // Handle error if necessary
    } finally {
      setIsDataLoading(false);
    }
  };

  const fetchFilterData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${constants.apiUrl}/support/client`, {
        headers,
        withCredentials: true,
      });

      const responseArr = response.data;
      const clientIds = responseArr.map((obj) => obj.client_id); // Extract client_id values
      setClientFilter(clientIds);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  // Fetch data for the initial page

  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForPage(currentPage, pageSize, searchQuery);
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    }
  }, [currentPage, pageSize, searchQuery, selectedClient, selectedOption]);

  useEffect(() => {
    fetchFilterData();
  }, []);

  const exportToCSV = () => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/support/product-dims-by-sku`, {
          headers,
          withCredentials: true,
          params: {
            // page: page,
            // pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
            client: selectedClient,
            days: selectedOption,
            exportCSV: true,
          },
        })
        .then((response) => {
          const data = response.data.users;

          const columnsData = [
            {
              Header: "Client",
              accessor: "ClientName", // Assuming the accessor contains unique identifiers
            },
            {
              Header: "SKU",
              accessor: "SKU",
            },
            {
              Header: "EAN Barcode",
              accessor: "EANBarcode",
            },
            {
              Header: "Qty",
              accessor: "StockLevel",
            },
            {
              Header: "Weight",
              accessor: "Weight",
            },
            {
              Header: "Height",
              accessor: "Height",
            },
            {
              Header: "Depth",
              accessor: "Depth",
            },
            {
              Header: "Length",
              accessor: "Length",
            },
            {
              Header: "VolumetricSize",
              accessor: "VolumetricSize",
            },
          ];

          const separator = ",";
          const csvContent =
            columnsData.map((column) => column.Header).join(separator) +
            "\n" +
            data
              .map((row) =>
                columnsData
                  .map((column) => {
                    let cell = row[column.accessor];
                    if (cell === null || cell === undefined) {
                      cell = "";
                    } else if (
                      typeof cell === "object" &&
                      cell.hasOwnProperty("value")
                    ) {
                      cell = cell.value;
                    }
                    cell = cell.toString().replace(/"/g, '""');
                    cell = cell.toString().replace(/€/g, "");
                    if (
                      cell.includes(separator) ||
                      cell.includes("\n") ||
                      cell.includes('"')
                    ) {
                      cell = `"${cell}"`;
                    }
                    return cell;
                  })
                  .join(separator)
              )
              .join("\n");

          const csvBlob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
          });
          const csvURL = window.URL.createObjectURL(csvBlob);

          // Generate file name
          const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
          let fileName;
          if (selectedClient) {
            fileName = `productdims-uncaptured-${selectedClient}-${timestamp}.csv`;
          }
          if (selectedOption) {
            fileName = `productdims-uncaptured-${selectedOption}-${timestamp}.csv`;
          } else if (selectedClient && selectedOption) {
            fileName = `productdims-uncaptured-${selectedClient}-${selectedOption}-${timestamp}.csv`;
          } else {
            fileName = `productdims-uncaptured-${timestamp}.csv`;
          }

          const tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", fileName);
          tempLink.click();

          resolve(); // Resolve the Promise after successful export
        })
        .catch((error) => {
          console.error("Error exporting csv data:", error);
          reject(error); // Reject the Promise if there's an error
        });
    });
  };

  // Function to handle button click event
  const handleExportButtonClick = () => {
    exportToCSV()
      .then(() => {
        console.log("CSV export completed successfully");
      })
      .catch((error) => {
        console.error("CSV export failed:", error);
      });
  };

  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
      manualPagination: true,
      pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <>
            <Flex
              px="25px"
              justify="space-between"
              ml="auto"
              mr="auto"
              mb={"10px"}
              align="center"
            >
              <Text
                color={textColor}
                fontSize="22px"
                fontWeight="700"
                lineHeight="100%"
              >
                Report By SKU - {selectedClient ? selectedClient : "All"} |
                Uncaptured SKUs {uncapturedCount}
              </Text>
            </Flex>
          </>
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            {/* Dropdown for Clients */}
            <Select
              ml={2}
              value={selectedClient}
              flex="2"
              onChange={(e) => {
                setSelectedClient(e.target.value);
              }}
            >
              <option value="">All Clients</option>
              {clientFilter.map((client) => (
                <option key={client} value={client}>
                  {client}
                </option>
              ))}
            </Select>

            <Select
              ml={2}
              value={selectedOption}
              flex="2"
              onChange={(e) => {
                setSelectedOption(e.target.value);
              }}
            >
              <option value="">Select a date range</option>
              {dateOptions.map((day) => (
                <option key={day.value} value={day.value}>
                  {day.label}
                </option>
              ))}
            </Select>

            {/* Search Input */}
            <Input
              ml={2}
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />

            <Icon
              as={FiDownload}
              onClick={handleExportButtonClick}
              variant="brand"
              w={"4em"}
              h={"1.5em"}
              marginTop={"5px"}
            >
              Export
            </Icon>

             {/* Bulk Action */}
             <Button ml={2}  variant="brand" onClick={handleBulkAction} disabled={selectedSKUs.length === 0}>
              Mark as Captured
            </Button>
          </Flex>

          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                   <Th>
                   <Checkbox
                    onChange={handleHeaderCheckboxChange}
                    isChecked={selectedSKUs.length === page.length && page.length > 0}
                  />
                  </Th>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length + 1} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                       <Td>
                       <Checkbox
                          isChecked={selectedSKUs.some((selectedRow) => selectedRow.SKU === row.original.SKU)}
                          onChange={(e) => handleCheckboxChange(e, row.original)}
                        />
                      </Td>
                      {row.cells.map((cell, cellIndex) => {
                        let data;

                        // Render the grouped dimensions and weight in a single cell
                        if (cell.column.id === "DimensionsAndWeight") {
                          data = cell.render("Cell");
                        } else {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        }

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Bulk Action
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to mark the selected SKUs as captured?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmBulkAction} ml={3}>
                Yes, Mark as Captured
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
}
