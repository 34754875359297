import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
// Custom components
// import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import illustration from "assets/img/auth/cropped-logo.png";

export function SidebarBrand({ brandLink }) {
  //   Chakra color mode
  return (
    <Flex align='center' direction='column' >
      <Link
        to={brandLink} // Change this route to match your actual route
        color="green.500"
      >
      <Image w='200px' h='100px'
    objectFit='contain' src={illustration} />
</Link>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
