// Chakra imports
import {
    Box,
    Flex,
    SimpleGrid,
    useColorModeValue,
    Icon,
    Spinner,
    Link as ChakraLink
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

import "react-date-range/dist/styles.css"; // Import the default styles
import "react-date-range/dist/theme/default.css"; // Import the default theme  
import PieCard from "views/customer/carrierPerformance/components/PieCard";
import ClientPieCard from "views/customer/carrierPerformance/components/ClientPieCard";
import ShipmentTraffic from "views/customer/carrierPerformance/components/ShipmentTraffic";
import ShipmentGraph from "views/customer/allShipmentSummary/components/ShipmentGraph";
import { MdLocalShipping, MdCancel, MdAccessTime, MdCheckCircle, MdAirportShuttle, MdAssignmentTurnedIn  } from "react-icons/md";

// Assets
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";

export default function ShipmentSummary() {
    // State to store fetched data
    const [isLoading, setIsLoading] = useState(true);
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [performanceData, setPerformanceData] = useState([]);

      // Fetch data using Axios (you may need to replace the API endpoint)
  const fetchData = async () => {
    try {
        setIsLoading(true);
      
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/customer/shipment-summary`,
        {
          headers,
          withCredentials: true,
          params: { days: selectedOption['value'] },
        }
      );

      const data = response.data;
      setPerformanceData(data); // Update performance data based on API response
      setIsLoading(false);
      
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if necessary
      setIsLoading(false);
    }
  };

  const handleDaysChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Add any additional logic you need for handling the change in selected days
    console.log(`Selected: ${selectedOption.value}`);
};


  const dateOptions = [
    { value: "1", label: "Last 1 day" },
    { value: "7", label: "Last 7 days" },
    { value: "30", label: "Last 30 days" },
    { value: "90", label: "Last 90 days" },
    { value: "180", label: "Last 180 days" },
    { value: "365", label: "Last 365 days" },
    { value: "1000", label: "Last 1000 days" },
  ];

  const [selectedOption, setSelectedOption] = useState(dateOptions[1]);

   // useEffect to fetch data when selected filters change
   useEffect(() => {
    fetchData();
  }, [selectedOption]); // Trigger fetch when selected filters change

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    );
  }
  
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
           
             <Flex justifyContent="flex-end"  mb={2}>
             <Select
                        value={selectedOption}
                        onChange={handleDaysChange}
                        options={dateOptions}
                        placeholder="Select a date range"
                       
                    />
            </Flex>
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                gap="20px"
                mb="20px"
            >
                  <ChakraLink href={`/#/customer/shipment-data?dataFor=allShipments&days=${selectedOption['value']}`}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdLocalShipping}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="Total Shipments"
                    value={performanceData.totalShipments}
                />
                </ChakraLink>
                <ChakraLink href={`/#/customer/shipment-data?dataFor=allDelays&days=${selectedOption['value']}`}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdCancel}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="# of Delays"
                    value={performanceData.totalDelays}
                />
                </ChakraLink>
                <ChakraLink href={`/#/customer/shipment-data?dataFor=overDueParcels&days=${selectedOption['value']}`}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdAccessTime}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="Over Due Parcels"
                    value={performanceData.overdueParcels}
                />
                </ChakraLink>
                <ChakraLink href={`/#/customer/shipment-data?dataFor=oneDayDelay&days=${selectedOption['value']}`}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdAccessTime}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="1 Day Delay"
                    value={performanceData.oneDayDelay}
                />
                </ChakraLink>
                <ChakraLink href={`/#/customer/shipment-data?dataFor=twoDayDelay&days=${selectedOption['value']}`}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdAccessTime}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="2 Day Delay"
                    value={performanceData.twoDayDelay}
                />
                  </ChakraLink>
                  <ChakraLink href={`/#/customer/shipment-data?dataFor=greaterThanTwoDayDelay&days=${selectedOption['value']}`}> 
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdAccessTime}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="<2 Day Delay"
                    value={performanceData.greaterThanTwoDayDelay}
                />
                 </ChakraLink>
                 <ChakraLink href={`/#/customer/shipment-data?dataFor=allInTransit&days=${selectedOption['value']}`}> 
                 <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdAirportShuttle}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="In transit"
                    value={performanceData.inTransitCount}
                />
                </ChakraLink>
                <ChakraLink href={`/#/customer/shipment-data?dataFor=allDelivered&days=${selectedOption['value']}`}> 
                 <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdCheckCircle}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="Packages Delivered"
                    value={performanceData.deliveredCount}
                />
                </ChakraLink>
                 <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdAssignmentTurnedIn}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="Total Packages being Tracked"
                    value={performanceData.totalPackagesBeingTracked}
                />
            </SimpleGrid>
              {performanceData.reports && ( <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                   <PieCard  data={performanceData.reports.data}  labels={performanceData.reports.labels}/>

                   {performanceData.reportsClient && (
                   <ClientPieCard  data={performanceData.reportsClient.data}  labels={performanceData.reportsClient.labels}/>
              )}
              </SimpleGrid> )}
              <Flex>
            {performanceData.carrierBarReports && (
                <SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap='20px' mt={6} width="50%">
                <ShipmentTraffic data={performanceData.carrierBarReports.data} labels={performanceData.carrierBarReports.labels} totalShipments={performanceData.totalShipments} />
                </SimpleGrid>
            )}

            {performanceData.lineGraph && (
                <SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap='20px' mt={6} width="50%">
                <ShipmentGraph data={performanceData.lineGraph} labels={performanceData.lineGraphLabels} />
                </SimpleGrid>
            )}
            </Flex>

        </Box>
    );
}
