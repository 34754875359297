import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem, Text, Center, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";

function ClaimsSLA() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from your API endpoint
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`${constants.apiUrl}/customer/fetch-claims-sla`, {
          headers,
          withCredentials: true,
        });

        setData(response.data); // Assuming the API response is an array
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Run this effect only once on component mount

// Define the excluded carrier names
const excludedCarriers = ["OTHER", "LTLCLIENT", "LTLGREEN", "REWORK", "RETURN", "CUSTPICKUP", "ERROR", "TBRGREEN", "TBRCLIENT"];

// Group data by carrier_name excluding specified carriers
const groupedData = data
  .filter(item => !excludedCarriers.includes(item.carrier_name))
  .reduce((acc, item) => {
    const key = item.carrier_name;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});


  return (
    <Box p="4" mt={16}>
      <Accordion allowToggle>
        {Object.entries(groupedData).map(([carrierName, items]) => (
          <AccordionItem key={carrierName} >
            <h2>
              <AccordionButton border={0}>
                <Box flex="1" textAlign="left">
                  {carrierName}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Grid
                templateColumns="repeat(4, 1fr)"
                gap={6}
                border="1px solid"
                borderRadius="md"
                overflow="hidden"
              >
                {/* Header Row */}
                <GridItem colSpan={1} bg="gray.200" p="2">
                  <Center>
                    <Text fontWeight="bold">Courier Service ID</Text>
                  </Center>
                </GridItem>
                <GridItem colSpan={1} bg="gray.200" p="2">
                  <Center>
                    <Text fontWeight="bold">Carrier Name</Text>
                  </Center>
                </GridItem>
                <GridItem colSpan={1} bg="gray.200" p="2">
                  <Center>
                    <Text fontWeight="bold">SLA for Lost(Days)</Text>
                  </Center>
                </GridItem>
                <GridItem colSpan={1} bg="gray.200" p="2">
                  <Center>
                    <Text fontWeight="bold">SLA for Damage(Days)</Text>
                  </Center>
                </GridItem>

                {/* Data Rows */}
                {items.map((item) => (
                  <React.Fragment key={item.courier_service_id}>
                    <GridItem colSpan={1} p="2" borderBottom="1px solid" borderColor="gray.300">
                      <Center>{item.courier_service_id}</Center>
                    </GridItem>
                    <GridItem colSpan={1} p="2" borderBottom="1px solid" borderColor="gray.300">
                      <Center>{item.carrier_name}</Center>
                    </GridItem>
                    <GridItem colSpan={1} p="2" borderBottom="1px solid" borderColor="gray.300">
                      <Center>{item.days_to_allow_lost}</Center>
                    </GridItem>
                    <GridItem colSpan={1} p="2" borderBottom="1px solid" borderColor="gray.300">
                      <Center>{item.days_to_allow_damaged}</Center>
                    </GridItem>
                  </React.Fragment>
                ))}
              </Grid>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}

export default ClaimsSLA;
