import React, { useState, useEffect } from "react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
import {
  Box,
  SimpleGrid,
  Spinner,
  Text,
  Flex,
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
} from "@chakra-ui/react";

export default function SubmittedClaims() {
  const [loading, setIsLoading] = useState(false);
  const [claims, setClaims] = useState([]);
  const borderColor = useColorModeValue("white", "gray.800");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("accessToken");
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${constants.apiUrl}/customer/submitted-claims`,
          {
            headers,
            withCredentials: true,
            params: { userId: userDetails.userId },
          }
        );

        setClaims(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching claims:", error);
        setIsLoading(false);
      }
    };

    fetchData(); // Call the fetchData function inside useEffect
  }, []); // Empty dependency array to run the effect only once

  return (
    <Box  pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <SimpleGrid columns={1} spacing={4}>
          {claims.length === 0 ? (
            <Text>No submitted claims found.</Text>
          ) : (
            claims.map((claim) => (
              <Box
                key={claim.claimKey}
                borderWidth="1px"
                borderRadius="lg"
                p={4}
                shadow="md"
                bg={borderColor}
              >
                <Flex justify="space-between">
                  <Text fontWeight="bold">
                    Tracking Number: {claim.trackingNumber}
                  </Text>
                  <Badge
                    colorScheme={claim.status === "Approved" || claim.status === "Credited" ? "green" : "red"}
                  >
                    {claim.status}
                  </Badge>
                </Flex>
                <Text mt={2} fontSize="sm" fontWeight="bold">
                  Claim Type: {claim.type}
                </Text>
                <Text mt={2} fontSize="sm" fontWeight="bold">
                  Comments: {claim.comments}
                </Text>
                <Text mt={2} fontSize="sm" fontWeight="bold">
                  Item Details:
                </Text>
                <SimpleGrid columns={1} spacing={4}>
                  {claim.skuDetails.map((skuDetail, index) => (
                    <Box
                      key={index}
                      borderWidth="1px"
                      borderRadius="md"
                      p={2}
                    >
                      <Accordion allowToggle>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                SKU: {skuDetail.sku}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {skuDetail.description && (
                              <Text>Description: {skuDetail.description}</Text>
                            )}
                            {skuDetail.totalCostPrice && (
                              <Text>Total Cost Price: {skuDetail.totalCostPrice}</Text>
                            )}
                            {skuDetail.totalSalesPrice && (
                              <Text>Total Sales Price: {skuDetail.totalSalesPrice}</Text>
                            )}
                            {skuDetail.weight && (
                              <Text>Weight: {skuDetail.weight}</Text>
                            )}
                            {skuDetail.descriptionOfDamage && (
                              <Text>Description of Damage: {skuDetail.descriptionOfDamage}</Text>
                            )}
                            {skuDetail.totalCostOfProduct && (
                              <Text>Total Cost of Product: {skuDetail.totalCostOfProduct}</Text>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
            ))
          )}
        </SimpleGrid>
      )}
    </Box>
  );
}
