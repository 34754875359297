// Chakra imports
import {
  Box,
  Flex,
  Text,
  Select,
  SimpleGrid,
  useColorModeValue,
  FormLabel,
  Icon,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Spinner,
  Link as ChakraLink
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Import the default styles
import "react-date-range/dist/theme/default.css"; // Import the default theme  
import PieCard from "views/support/co2EmissionsReport/components/PieCard";
import ClientPieCard from "views/support/co2EmissionsReport/components/ClientPieCard";
import ShipmentTraffic from "views/support/co2EmissionsReport/components/ShipmentTraffic";
import { MdLocalShipping, MdEco  } from "react-icons/md";

// Assets
import React, { useState, useEffect } from "react";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";

export default function CO2EmissionsReport() {
  // State to store fetched data
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [performanceData, setPerformanceData] = useState([]);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const today = new Date();
  const startOfLast30Days = new Date(today);
  startOfLast30Days.setDate(today.getDate() - 30);
  startOfLast30Days.setHours(0, 0, 0, 0);


  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfLast30Days,
    endDate: today,
    key: "selection",
  });

  const [formattedDateRange, setFormattedDateRange] = useState('');
  // Fetch data using Axios (you may need to replace the API endpoint)
  const [filterData, setFilterData] = useState({
    clients: [],
    warehouses: [],
    carriers: [],
    carrierServices: [],
  });

  // State to store selected filters
  const [selectedFilters, setSelectedFilters] = useState({
    client: "",
    warehouse: "",
    carrierName: "",
    courierServiceName: "",
  });

  const updateFormattedDateRange = (selection) => {
    if (selection) {
      const formattedStartDate = selection.startDate.toLocaleDateString();
      const formattedEndDate = selection.endDate.toLocaleDateString();
      setFormattedDateRange(`${formattedStartDate} - ${formattedEndDate}`);
    } else {
      setFormattedDateRange('');
    }
  };
  // Event handler for changing the client filter
  const handleClientChange = (event) => {
    const clientValue = event.target.value;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      client: clientValue,
    }));
  };

  // Event handler for changing the warehouse filter
  const handleWarehouseChange = (event) => {
    const warehouseValue = event.target.value;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      warehouse: warehouseValue,
    }));
  };

  // Event handler for changing the carrier filter
  const handleCarrierChange = (event) => {
    const carrierValue = event.target.value;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      carrierName: carrierValue,
    }));
  };

  // Event handler for changing the carrier service filter
  const handleCarrierServiceChange = (event) => {
    const carrierServiceValue = event.target.value;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      courierServiceName: carrierServiceValue,
    }));
  };

  // Fetch data using Axios (you may need to replace the API endpoint)
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/support/co2-metrics`,
        {
          headers,
          withCredentials: true,
          params: {
            ...selectedFilters, // Spread selectedFilters
            startDate: selectedDateRange.startDate.toISOString(), // Convert to ISO format
            endDate: selectedDateRange.endDate.toISOString(), // Convert to ISO format
          },
        }
      );

      const data = response.data;
      setPerformanceData(data); // Update performance data based on API response
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if necessary
      setIsLoading(false);
    }
  };

  // useEffect to fetch data when selected filters change
  useEffect(() => {
    fetchData();
  }, [selectedFilters, selectedDateRange]); // Trigger fetch when selected filters change

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${constants.apiUrl}/support/fetch-tracking-module-filters`,
          {
            headers,
            withCredentials: true,
          }
        );

        const data = response.data;

        // Set the filter data in state
        setFilterData({
          clients: data.clients,
          warehouses: data.warehouseNames,
          carriers: data.carrierNames,
          carrierServices: data.courierServiceNames,
        });
      } catch (error) {
        console.error("Error fetching filter data:", error);
        // Handle error if necessary
      }
    };

    fetchFilterData();
  }, []); // Empty dependency array ensures the effect runs once on mount


  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid gap="20px" mb="20px" columns={{ base: 1, md: 2, lg: 4 }}>
        {/* Filters */}
        <Flex align="center">
          <Select onChange={handleClientChange} value={selectedFilters.client}>
            <option value="">All Clients</option>
            {filterData.clients.map((client) => (
              <option key={client} value={client}>
                {client}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex align="center">
          <Select onChange={handleWarehouseChange} value={selectedFilters.warehouse}>
            <option value="">All Warehouses</option>
            {filterData.warehouses.map((warehouse) => (
              <option key={warehouse} value={warehouse}>
                {warehouse}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex align="center">
          <Select onChange={handleCarrierChange} value={selectedFilters.carrierName}>
            <option value="">All Carrier</option>
            {filterData.carriers.map((carrier) => (
              <option key={carrier} value={carrier}>
                {carrier}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex align="center">
          <Select onChange={handleCarrierServiceChange} value={selectedFilters.courierServiceName}>
            <option value="">All Courier Services</option>
            {filterData.carrierServices.map((carrierService) => (
              <option key={carrierService} value={carrierService}>
                {carrierService}
              </option>
            ))}
          </Select>
        </Flex>
      </SimpleGrid>

      <SimpleGrid gap="20px" mb="20px" columns={{ base: 1, md: 2, lg: 1 }}>
        {/* Date Range Picker */}
        <Accordion
          allowToggle
          onChange={() => setIsDateRangePickerOpen(!isDateRangePickerOpen)}
        >
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <FormLabel>
                  Date Range Filter:{' '}
                  {formattedDateRange && (
                    <Text as="span" color="blue.500">
                      {formattedDateRange}
                    </Text>
                  )}
                </FormLabel>
              </Box>
            </AccordionButton>
            <AccordionPanel>
              {isDateRangePickerOpen && (
                <DateRangePicker
                  ranges={[selectedDateRange]}
                  onChange={(ranges) => {
                    setSelectedDateRange(ranges.selection);
                    updateFormattedDateRange(ranges.selection);
                  }}
                />
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        gap="20px"
        mb="20px"
      >
        <ChakraLink href={`/#/support/shipment-data?dataFor=allShipments&${new URLSearchParams({
    ...selectedFilters,
    startDate: selectedDateRange.startDate.toISOString(),
    endDate: selectedDateRange.endDate.toISOString(),
  })}`}>
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdLocalShipping}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Shipments"
            value={performanceData.totalShipments}
          />
        </ChakraLink>
        <ChakraLink href={`/#/support/shipment-data?dataFor=co2Calculated&${new URLSearchParams({
    ...selectedFilters,
    startDate: selectedDateRange.startDate.toISOString(),
    endDate: selectedDateRange.endDate.toISOString(),
  })}`}>
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdLocalShipping}
                  color={brandColor}
                />
              }
            />
          }
          name="# of Carbon Tracked Shipments"
          value={performanceData.totalTrackedCo2Shipments}
        />
          </ChakraLink>
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdEco}
                  color={brandColor}
                />
              }
            />
          }
          name="Carbon Emitted"
          value={`${performanceData.totalCarbonEmission} Kg CO2e`}
        />
 
      </SimpleGrid>
      {performanceData.reports && (<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
        <PieCard data={performanceData.reports.data} labels={performanceData.reports.labels} />

        {performanceData.reportsClient && (
          <ClientPieCard data={performanceData.reportsClient.data} labels={performanceData.reportsClient.labels} />
        )}
      </SimpleGrid>)}

      {performanceData.carrierBarReports && (<SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap='20px' mt={6}>
        <ShipmentTraffic data={performanceData.carrierBarReports.data} labels={performanceData.carrierBarReports.labels} totalShipments={performanceData.totalCarbonEmission} />
      </SimpleGrid>)}

      {performanceData.clientBarReports && (<SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap='20px' mt={6}>
        <ShipmentTraffic data={performanceData.clientBarReports.data} labels={performanceData.clientBarReports.labels} totalShipments={performanceData.totalCarbonEmission} />
      </SimpleGrid>)}
    </Box>
  );
}
