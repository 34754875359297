// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";
import jwt_decode from 'jwt-decode';
// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  const token = localStorage.getItem('accessToken');
  const decodedToken = jwt_decode(token);
  const roleArr = decodedToken.role.split(",").map(role => role.trim()); // Split and trim roles

  const filteredRoutes = routes.filter((route) => {
    if (!route.hideInMenu) {
      const routeRoles = route.accessRole.split(",").map(role => role.trim()); // Split and trim access roles
      // Check if there's an intersection between the user's roles and the route's access roles
      return roleArr.some(userRole => routeRoles.includes(userRole));
    }
    return false; // Include routes with hideInMenu set to true
  });
   // Determine the Brand link based on user roles
   const brandLink = roleArr.includes('Customer') ? '/customer/dashboard' : '/generic/dashboard';
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
        <Box>
        <Brand brandLink={brandLink} />
      </Box>
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={filteredRoutes} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
