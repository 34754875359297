import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Box, Flex, Text, Divider } from "@chakra-ui/react";
import Card from "components/card/Card";
import './Timeline.css';

// Function to parse the date string into a JavaScript Date object
const parseDateString = (dateString) => {
  // Split the date string into date and time parts
  const [datePart, timePart] = dateString.split(', ');
  // Split the date part into day, month, and year
  const [day, month, year] = datePart.split('/');
  // Split the time part into hours, minutes, and seconds
  const [time, meridian] = timePart.split(' ');
  const [hours, minutes, seconds] = time.split(':');

  // Adjust the hours for AM/PM format
  let adjustedHours = parseInt(hours, 10);
  if (meridian === 'pm' && adjustedHours < 12) {
    adjustedHours += 12;
  }

  // Create a new Date object with the parsed date and time
  return new Date(year, month - 1, day, adjustedHours, minutes, seconds);
};

// Function to calculate the time interval between two events
const calculateTimeInterval = (firstEvent, secondEvent) => {
  // Parse the date strings into Date objects
  let firstDate = parseDateString(firstEvent.eventDateTime);
  let secondDate = parseDateString(secondEvent.eventDateTime);
  
  // Calculate the difference in milliseconds
  let difference = secondDate - firstDate;

  // Check if the second event occurs before the first event
  if (difference < 0) {
    // Swap the dates
    const tempDate = firstDate;
    firstDate = secondDate;
    secondDate = tempDate;
    difference = -difference; // Update difference to be positive
  }

  // Convert the time difference to days, hours, and minutes
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  difference -= days * (1000 * 60 * 60 * 24);

  const hours = Math.floor(difference / (1000 * 60 * 60));
  difference -= hours * (1000 * 60 * 60);

  const minutes = Math.floor(difference / (1000 * 60));

  return `${days} days ${hours} hours ${minutes} minutes`;
};


function TimelineView({ events }) {
  return (
    <Card mt={4}>
      <VerticalTimeline layout="1-column" lineColor="gray">
        {events.map((event, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element"
            contentArrowStyle={{ borderRight: "3px solid #ccc" }}
            contentStyle={{
              background: "transparent",
              padding: "8px", // Adjust the padding here to reduce space
              boxShadow: "none",
              margin: 0, // Set margin to 0
            }}
            iconStyle={{
              display: "flex",
              background: "#55cc55",
              fontSize: "2px", // Adjust the font size of the circle
            }}
          >
            <Flex h="50px">
              <Box width="33%" textAlign="center">
                <Flex direction="column">
                  <Text fontSize="xs" color="gray.500" fontWeight="light">
                    Date & Time
                  </Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {event.eventDateTime}
                  </Text>
                </Flex>
              </Box>
              <Divider orientation="vertical" h="80%" borderColor="gray.300" borderWidth="2px" />
              <Box width="33%" textAlign="center">
                <Flex direction="column">
                  <Text fontSize="xs" color="gray.500" fontWeight="light">
                    Event
                  </Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {event.eventName}
                  </Text>
                </Flex>
              </Box>
              <Divider orientation="vertical" h="80%" borderColor="gray.300" borderWidth="2px" />
              <Box width="33%" textAlign="center">
                <Flex direction="column">
                  <Text fontSize="xs" color="gray.500" fontWeight="light">
                    Location
                  </Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {event.locationName}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            {/* Display time interval difference for the last two events */}
            {index === events.length - 2 && (
              <Flex alignItems="center" justifyContent="center" mt={6}>
                <Divider orientation="horizontal" w="30%" borderColor="gray.300" />
                <Box textAlign="center" mx={4}>
                  <Text fontSize="sm" fontWeight="bold" textColor={"lightskyblue"}>
                  Time Interval: {calculateTimeInterval(events[events.length - 2], events[events.length - 1])}
                  </Text>
                </Box>
                <Divider orientation="horizontal" w="30%" borderColor="gray.300" />
              </Flex>
            )}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </Card>
  );
}

export default TimelineView;
