import React, { useState, useEffect } from "react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
import { Link } from "react-router-dom";

import {
    Box,
    Heading,
    Spinner,
    Text,
    Badge,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Icon,
    Grid,
    Divider,
    Button,
    Textarea,
    useToast,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    FormControl
} from "@chakra-ui/react";
import { FaFileDownload, FaFilePdf, FaFileImage, FaFile } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css"; // Import your custom CSS for DatePicker
import TimelineView from "./Timeline";

export default function ViewClaim(props) {

    const statusValidationRules = {
        "Pending": {
            requiredFields: ["comments"],
            errorMessage: "Comments are required!",
        },
        "In Progress": {
            requiredFields: ["comments", "carrierClaimId"],
            errorMessage: "Comments and Carrier Claim ID are required!",
        },
        "Approved": {
            requiredFields: ["comments", "amountReceived"],
            errorMessage: "Comments and Amount Credited are required!",
        },
        "Credited": {
            requiredFields: ["comments", "creditNoteNumber", "dateOfCreditNote"],
            errorMessage: "Credit Note Number and Date of Credit Note are required!",
        },
        "Rejected": {
            requiredFields: ["comments"],
            errorMessage: "Comments is required!",
        },
    };

    const parseDate = (dateStr) => {
        const parts = dateStr.split('-');
        if (parts.length === 3) {
            const month = parseInt(parts[0], 10) - 1; // Month is zero-based
            const day = parseInt(parts[1], 10);
            const year = parseInt(parts[2], 10);

            if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
                return new Date(year, month, day, 12, 0, 0); // Noon (12:00:00) to provide a valid time
            }
        }
        return null;
    };

    const downloadAttachments = (attachmentUrls) => {
        // Create an array to store all anchor elements
        const anchors = [];

        // Loop through the attachmentUrls and create anchors for each attachment
        attachmentUrls.forEach((url) => {
            const anchor = document.createElement("a");
            anchor.href = url.url;

            // Extract the filename from the URL (assuming it's the last part before the '?')
            const filename = url.fileName;

            // Set the download attribute with the filename
            anchor.setAttribute("download", filename);

            // Set the target attribute to "_blank" for opening in a new tab
            anchor.setAttribute("target", "_blank");

            anchors.push(anchor);
        });

        // Trigger a click event on each anchor element to initiate the downloads
        anchors.forEach((anchor) => {
            console.log(anchor);
            anchor.click();
        });
    };



    const getQueryParams = (url) => {
        const queryString = url.split("?")[1];
        if (!queryString) {
            return {};
        }

        const paramsArray = queryString.split("&");
        const params = {};

        paramsArray.forEach(param => {
            const [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
        });

        return params;
    };

    const toast = useToast();
    const windowUrl = window.location.href;
    const queryParams = getQueryParams(windowUrl);
    const trackingNumber = queryParams["trackingNumber"];
    const type = queryParams["type"];

    const [claimData, setClaimData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);

    const [claimUpdateData, setClaimUpdateData] = useState({
        comments: "",
        selectedStatus: "",
        carrierClaimId: "", // New field for "Carrier Claim ID"
        amountCredited: 0.00, // New field for "Amount Credited (ex VAT)"
        amountReceived: 0.00,
        creditNoteNumber: "", // New field for "Credit Note Number"
        dateOfCreditNote: ""
    });

    const fetchData = async () => {
        try {
            const token = localStorage.getItem("accessToken");
            // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.get(`${constants.apiUrl}/support/fetch-claim-details`, {
                headers,
                withCredentials: true,
                params: { trackingNumber, claimType: type },
            });

            const options = {
                timeZone: 'Europe/London',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };

            const formattedCreatedAt = new Intl.DateTimeFormat('en-GB', options).format(new Date(response.data[0].createdAt));
            response.data[0].createdAt = formattedCreatedAt;
            if(type === "Late"){
                const formattedExpectedDeliveryDate = new Intl.DateTimeFormat('en-GB', options).format(new Date(response.data[0].expectedDeliveryDate));
                const formattedDeliveryDate = new Intl.DateTimeFormat('en-GB', options).format(new Date(response.data[0].deliveryDate));
                const formattedLastStatusDateTime = new Intl.DateTimeFormat('en-GB', options).format(new Date(response.data[0].lastStatusDateTime));

                response.data[0].expectedDeliveryDate = formattedExpectedDeliveryDate;
                response.data[0].deliveryDate = formattedDeliveryDate;
                response.data[0].lastStatusDateTime = formattedLastStatusDateTime;
            }
            setClaimData(response.data[0]);
            // Update claimUpdateData with comments and status
            const updatedClaimUpdateData = { ...claimUpdateData };
            updatedClaimUpdateData.comments = response.data[0].comments;
            updatedClaimUpdateData.selectedStatus = response.data[0].status;

            updatedClaimUpdateData.amountCredited = response.data[0].amountCredited === null ? 0.00 : response.data[0].amountCredited;
            updatedClaimUpdateData.amountReceived = response.data[0].amountReceived;
            updatedClaimUpdateData.carrierClaimId = response.data[0].carrierClaimId;
            updatedClaimUpdateData.creditNoteNumber = response.data[0].creditNoteNumber;
            if (response.data[0].dateOfCreditNote !== null && response.data[0].dateOfCreditNote !== "") {
                const dateObject = parseDate(response.data[0].dateOfCreditNote);

                updatedClaimUpdateData.dateOfCreditNote = dateObject;
            }

            setClaimUpdateData(updatedClaimUpdateData);

            setLoading(false);
        } catch (error) {
            console.error("Error fetching claim details:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [props.location.search]);

    if (loading) {
        return (
            <Flex height="100vh" alignItems="center" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="green.500" />
            </Flex>
        );
    }

    if (!claimData) {
        return <Text>No claim data found.</Text>;
    }

    const calculateAmountCredited = (e) => {
        setClaimUpdateData((prevData) => {
            const amountReceived = parseFloat(e.target.value) || 0;
            const adminDeductible = parseFloat(claimData.adminDeductible) || 0;

            // Check if amountReceived is not a number or not entered
            if (isNaN(amountReceived) || amountReceived <= 0) {
                return { ...prevData, amountReceived, amountCredited: 0.00 };
            } else {
                return {
                    ...prevData,
                    amountReceived,
                    amountCredited: (amountReceived - adminDeductible).toFixed(2),
                };
            }
        });
    };
    const handleUpdateClaim = () => {


        const token = localStorage.getItem("accessToken");
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));

        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Fixed content type typo
        };



        // Get the required fields and error message based on the selected status
        const { requiredFields, errorMessage } = statusValidationRules[claimUpdateData.selectedStatus];

        // Validate the required fields
        for (const field of requiredFields) {
            if (!claimUpdateData[field]) {
                toast({
                    position: "top",
                    title: errorMessage,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                setIsFormValid(false);
                return;
            }
        }
        setIsFormValid(true);
        // Format the date explicitly as "MM-dd-yyyy" before sending it
        if (claimUpdateData.dateOfCreditNote) {
            const dateFormat = claimUpdateData.dateOfCreditNote.toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            // Split the date by slashes, reverse it, and join with hyphens
            const formattedDate = dateFormat.split("/").join("-");
            claimUpdateData.dateOfCreditNote = formattedDate;
        }

        axios
            .post(
                `${constants.apiUrl}/support/update-claim`,
                {
                    trackingNumber: trackingNumber,
                    type: type,
                    claimUpdateData: claimUpdateData,
                    userId: userDetails.userId,
                },
                { headers, withCredentials: true }
            )
            .then((response) => {
                if (response.status === 200) {
                    toast({
                        position: "top",
                        title: "Claim Updated Successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    // fetchData();
                } else {
                    toast({
                        position: "top",
                        title: "Error Updating Claim, Please try again! ",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                const errorMessage = error.response.data.message || "Error Updating Claim, Please try again!";
                toast({
                    position: "top",
                    title: errorMessage,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                // fetchData();
                console.error("Error submitting claim:", error);
            });
    };



    return (
        <Box p={4} mt={4}>
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Heading size="lg">Claim Details</Heading>
                <Link to="/submitted-claims">Back to Claims List</Link>
            </Flex>
            <Tabs variant="enclosed" isFitted colorScheme="teal">
                <TabList>
                    <Tab _selected={{ color: 'white', bg: 'green.400' }}>Claim Details</Tab>
                    <Tab _selected={{ color: 'white', bg: 'green.400' }}>Timeline</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Flex justifyContent="space-between" mb={4}>
                            <Box>
                                <Text fontWeight="bold">Tracking Number:</Text>
                                <Text>{claimData.trackingNumber}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Carrier:</Text>
                                <Text>{claimData.carrierName}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Claim Type:</Text>
                                <Badge
                                    colorScheme={
                                        claimData.type === "Damaged" ? "orange" :
                                            claimData.type === "Late" ? "yellow" :
                                                "blue"
                                    }
                                >
                                    {claimData.type}
                                </Badge>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Status:</Text>
                                <Badge
                                    colorScheme={
                                        claimData.status === "Approved" || claimData.status === "Credited" ? "green" : "red"
                                    }
                                >
                                    {claimData.status}
                                </Badge>
                            </Box>
                            {claimData.type !== "Late" && (
                                <Box>
                                    <Text fontWeight="bold">Total Claim Value:</Text>
                                    <Text>{`£${claimData.totalClaimValue}`}</Text>
                                </Box>
                            )}
                            <Box>
                                <Text fontWeight="bold">Client:</Text>
                                <Text >{claimData.clientCode}</Text>

                            </Box>
                            <Box>
                                <Text fontWeight="bold">Raised By:</Text>
                                <Text >{claimData.raisedBy}</Text>

                            </Box>

                            {claimData.type === "Late" && (
                                <>
                                    <Box>
                                        <Text fontWeight="bold">Created At:</Text>
                                        <Text >{claimData.createdAt}</Text>

                                    </Box>

                                    <Button colorScheme="brand">
                                        {/* <Text fontWeight="bold">View Tracking:</Text> */}
                                        <Link
                                            to={`/support/track-your-parcel?trackingNumber=${claimData.trackingNumber}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View Tracking
                                        </Link>
                                    </Button>
                                </>
                            )}
                        </Flex>
                        {claimData.type === "Late" && (
                        <Flex justifyContent="space-between" mb={4}>
                            <Box>
                                <Text fontWeight="bold">Expected Date:</Text>
                                <Text>{claimData.expectedDeliveryDate}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Delivered Date:</Text>
                                <Text>{claimData.deliveryDate}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Last Status:</Text>
                                <Text>{claimData.lastStatus}</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Last Status Date:</Text>
                                <Text>{claimData.lastStatusDateTime}</Text>
                            </Box>
                        </Flex>
                        )}

                        <Box backgroundColor={"white"}
                            p={4}
                            borderWidth="1px"
                            borderRadius="lg"
                            shadow="md"
                            my={4}
                            position={"relative"} >
                            {/* Add a "Update Claim" button at the top right corner */}
                            <Button
                                position="absolute"
                                top={2}
                                right={2}
                                colorScheme="teal"
                                onClick={handleUpdateClaim}
                            >
                                Update Claim
                            </Button>

                            {/* Add a select dropdown for status change */}
                            <Text fontWeight="bold" mt={4}>Change Status:</Text>
                            <Select
                                value={claimUpdateData.selectedStatus}
                                onChange={(e) => setClaimUpdateData({ ...claimUpdateData, selectedStatus: e.target.value })}
                                mt={2}
                            >
                                <option value="Pending">Pending</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Approved">Approved</option>
                                <option value="Credited">Credited</option>
                                <option value="Rejected">Rejected</option>
                            </Select>

                            <FormControl
                                isInvalid={!isFormValid && !claimUpdateData.comments}
                                errorBorderColor="red.300"
                            >
                                {/* Add a textarea for comments */}
                                <Text fontWeight="bold" mt={4}>Comments:</Text>
                                <Textarea
                                    value={claimUpdateData.comments}
                                    onChange={(e) => setClaimUpdateData({ ...claimUpdateData, comments: e.target.value })}
                                    placeholder="Enter your comments..."
                                    size="md"
                                    resize="vertical"
                                    rows={4}
                                    mt={2}
                                />
                            </FormControl>
                            <FormControl
                                isInvalid={!isFormValid}
                                errorBorderColor="red.300"
                            >
                                {claimUpdateData.selectedStatus === "In Progress" && (
                                    <>
                                        <Text fontWeight="bold" mt={4}>
                                            Carrier Claim ID:
                                        </Text>
                                        <Input
                                            value={claimUpdateData.carrierClaimId}
                                            onChange={(e) => setClaimUpdateData({ ...claimUpdateData, carrierClaimId: e.target.value })}
                                            mt={2}
                                        />
                                    </>
                                )}

                                {claimUpdateData.selectedStatus === "Approved" && (
                                    <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
                                        {claimData.type !== "Late" && (
                                            <Box>
                                                <Text fontWeight="bold" mt={4}>
                                                    Total Claim Value:
                                                </Text>
                                                <Text>{`£${claimData.totalClaimValue}`}</Text>
                                            </Box>
                                        )}
                                        <Box>
                                            <Text fontWeight="bold" mt={4}>
                                                Claim Amount Received
                                            </Text>
                                            <InputGroup>
                                                <InputLeftAddon>£</InputLeftAddon>
                                                <Input
                                                    type="number"
                                                    min={0}
                                                    value={claimUpdateData.amountReceived}
                                                    onChange={(e) => calculateAmountCredited(e)}

                                                    isRequired={true}
                                                />
                                            </InputGroup>
                                        </Box>

                                        <Box>
                                            <Text fontWeight="bold" mt={4}>
                                                Admin Deductible:
                                            </Text>
                                            <Text>{`£${claimData.adminDeductible}`}</Text>
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold" mt={4}>
                                                Credit Amount Available
                                            </Text>
                                            <Text>{`£${claimUpdateData.amountCredited}`}</Text>
                                        </Box>
                                    </Flex>
                                )}
                                {/* Add Credit Note Number input */}
                                {claimUpdateData.selectedStatus === "Credited" && (
                                    <Flex justifyContent="space-around" alignItems="flex-start" mb={4}>
                                        <Box>
                                            <Text fontWeight="bold" mt={4}>
                                                Credit Note Number:
                                            </Text>
                                            <Input
                                                type="number"
                                                min={0}
                                                value={claimUpdateData.creditNoteNumber}
                                                onChange={(e) => setClaimUpdateData({ ...claimUpdateData, creditNoteNumber: e.target.value })}
                                                mt={2}
                                            />
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold" mt={4}>Date of Credit Note</Text>
                                            <InputGroup>
                                                <DatePicker
                                                    minDate={new Date()}
                                                    selected={claimUpdateData.dateOfCreditNote}
                                                    onChange={(date) => setClaimUpdateData({ ...claimUpdateData, dateOfCreditNote: date })}
                                                    dateFormat="MM-dd-yyyy"
                                                    className="custom-datepicker"
                                                />
                                            </InputGroup>
                                        </Box>
                                    </Flex>
                                )}

                            </FormControl>
                        </Box>
                        {/* Display SKU Details */}
                        {type !== "Late" && (
                            <>
                                <Heading size="md" mb={2} mt={2}>
                                    Item Details
                                </Heading>
                                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                    {claimData.skuDetails.map((skuDetail, index) => (
                                        <Box
                                            key={index}
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            p={4}
                                            shadow="md"
                                            backgroundColor="white"
                                        >
                                            <Grid templateColumns="1fr 2fr" gap={2}>
                                                {skuDetail.sku && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">SKU:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.sku}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.description && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Description:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.description}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.weight && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Weight:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.weight}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.additionalDescription && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Additional Description:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.additionalDescription}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.quantity && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Quantity:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.quantity}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.quantityCostPrice && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Quantity:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.quantityCostPrice}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.costPerPiece && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Cost Per Piece:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.costPerPiece}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.totalCostOfProduct && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Total Cost Price:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.totalCostOfProduct}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.quantitySalesPrice && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Quantity:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.quantitySalesPrice}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.salesPrice && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Sales Price:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.salesPrice}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.totalSalesPrice && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Total Sales Price:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.totalSalesPrice}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                                {skuDetail.descriptionOfDamage && (
                                                    <>
                                                        <Box>
                                                            <Text fontWeight="bold">Description of Damage:</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text>{skuDetail.descriptionOfDamage}</Text>
                                                        </Box>
                                                    </>
                                                )}
                                            </Grid>

                                            {/* Display attachments here if they exist and the type is "Damaged" */}
                                            {skuDetail.attachments && type === "Damaged" && (
                                                <>
                                                    <Divider my={4} />
                                                    <Text fontWeight="bold">Attachments:</Text>
                                                    {skuDetail.attachments.map((attachment, index) => (
                                                        <Box key={index} mt={2}>
                                                            <Flex alignItems="center">
                                                                {attachment.type === "pdf" ? (
                                                                    <Icon as={FaFilePdf} mr={2} />
                                                                ) : attachment.type === "image" ? (
                                                                    <Icon as={FaFileImage} mr={2} /> // Use the appropriate image icon
                                                                ) : (
                                                                    <Icon as={FaFile} mr={2} /> // Use a generic file icon for other file types
                                                                )}
                                                                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                                                    {attachment.fileName}
                                                                </a>
                                                            </Flex>
                                                        </Box>
                                                    ))}
                                                    <Button
                                                        leftIcon={<FaFileDownload />}
                                                        onClick={() => downloadAttachments(skuDetail.attachments)}
                                                        mt={4}
                                                        colorScheme="teal"
                                                    >
                                                        Download All Attachments
                                                    </Button>
                                                </>
                                            )}
                                        </Box>
                                    ))}



                                </Grid>
                            </>
                        )}


                        {/* Display attachments outside of skuDetails for Lost claims */}
                        {claimData.attachments && type === "Lost" && (
                            <Box
                                backgroundColor={"white"}
                                p={4}
                                borderWidth="1px"
                                borderRadius="lg"
                                shadow="md"
                                my={4} // Adjust the margin as needed
                            >
                                <Text fontWeight="bold" fontSize="lg">
                                    Attachments
                                </Text>
                                {claimData.attachments.map((attachment, index) => (
                                    <Box key={index} mt={2}>
                                        <Flex alignItems="center">
                                            {attachment.type === "pdf" ? (
                                                <Icon as={FaFilePdf} mr={2} />
                                            ) : attachment.type === "image" ? (
                                                <Icon as={FaFileImage} mr={2} /> // Use the appropriate image icon
                                            ) : (
                                                <Icon as={FaFile} mr={2} /> // Use a generic file icon for other file types
                                            )}
                                            <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                                {attachment.fileName}
                                            </a>
                                        </Flex>
                                    </Box>

                                ))}
                                <Button
                                    leftIcon={<FaFileDownload />}
                                    onClick={() => downloadAttachments(claimData.attachments)}
                                    mt={4}
                                    colorScheme="teal"
                                    size="sm"
                                >
                                    Download All Attachments
                                </Button>
                            </Box>
                        )}

                    </TabPanel>
                    <TabPanel>
                        {/* Content for the "Timeline" tab */}
                        {claimData.events && <TimelineView events={claimData.events} />}
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Box>
    );
}
