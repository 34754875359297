import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";

function TimelineView({ events }) {
  return (
    <VerticalTimeline>
      {events.map((event, index) => (
        <VerticalTimelineElement
          key={index}
          className="vertical-timeline-element"
          contentArrowStyle={{ borderRight: "7px solid #ccc" }}
          date={event.updatedAt}
          iconStyle={{ background: "rgb(97 255 22)", color: "#fff" }}
        >
        <Flex alignItems="flex-start">
            <Avatar name={String(event.updatedBy)} size="sm" m="2" />
            <Text fontWeight="bold">{event.updatedBy}</Text>
          </Flex>
          {event.updates.map((update, updateIndex) => (
            <Box key={updateIndex}>
              {update.field && (
                <Text>
                  <strong>{update.field}</strong>
                  {update.oldValue !== null && update.oldValue !== "" ? (
                    ` value changed from "${update.oldValue}" to "${update.newValue}"`
                  ) : (
                    ` set to "${update.newValue}"`
                  )}
                </Text>
              )}
            </Box>
          ))}
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
}

export default TimelineView;
