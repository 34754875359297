import React, { useState, useEffect, useRef } from "react";
import axios from "components/axios/axiosInstance";
import {
    Box,
    Spinner,
    Flex,
    Input,
    Text,
    Button,
    List,
    ListItem,
    VStack,
    StackDivider,
    InputGroup,
    InputRightAddon,
    useToast,
    AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import Card from "components/card/Card";

const InputBox = ({ title, unit, value, onChange }) => {
    return (
        <Box>
            <Text fontWeight="bold">{title}</Text>
            <InputGroup size="sm" width="100%">
                <Input
                    w={"50%"}
                    type="number"
                    value={value}
                    bg="white"
                    border="1px solid #ccc"
                    borderRadius="sm"
                    onChange={onChange}
                />
                <InputRightAddon children={unit} bg="gray.100" />
            </InputGroup>
        </Box>
    );
};

export default function DimsDataCapture() {
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [productData, setProductData] = useState({
        SKU: "",
        ClientName: "",
        EANBarcode: "",
        Weight: "",
        Length: "",
        Depth: "",
        Height: "",
        VolumetricSize: "",
    });
    const toast = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchText(value);

        if (value.length >= 3) {
            const token = localStorage.getItem("accessToken");

            if (!token) {
                return;
            }

            const headers = {
                Authorization: `Bearer ${token}`,
            };

            axios
                .get(`${constants.apiUrl}/support/fetch-product-details?searchText=${value}&details=all`, {
                    headers,
                    withCredentials: true,
                })
                .then((response) => {
                    setSuggestions(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching suggestions:", error);
                    setSuggestions([]);
                });
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionSelect = (suggestion) => {
        setProductData(suggestion);
        setSuggestions([]);
        setSearchText(suggestion.SKU);
    };

    const handleInputChange = (e, key) => {
        const { value } = e.target;
        setProductData({
            ...productData,
            [key]: value,
        });
    };

    useEffect(() => {
        // Recalculate VolumetricSize whenever Length, Depth, or Height changes
        const { Length, Depth, Height } = productData;

        // Convert input values to numbers
        const parsedLength = parseFloat(Length);
        const parsedDepth = parseFloat(Depth);
        const parsedHeight = parseFloat(Height);

        // Check if all values are valid numbers
        if (!isNaN(parsedLength) && !isNaN(parsedDepth) && !isNaN(parsedHeight)) {
            // Calculate VolumetricSize
            const volumetricSize = (parsedLength * parsedDepth * parsedHeight).toFixed(2);
            setProductData(prevState => ({
                ...prevState,
                VolumetricSize: volumetricSize
            }));
        } else {
            // If any of the values is invalid, set VolumetricSize to an empty string
            setProductData(prevState => ({
                ...prevState,
                VolumetricSize: ""
            }));
        }
    }, [productData.Length, productData.Depth, productData.Height]);



    const handleSubmit = () => {
        // Set loading state to true
         // Close the dialog and proceed with submitting
         setIsOpen(false);

        setIsLoading(true);

        // Assuming you have an API endpoint to submit product data
        const token = localStorage.getItem("accessToken");
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        axios
            .post(`${constants.apiUrl}/support/submit-product-dims`, productData, {
                headers,
                withCredentials: true,
            })
            .then((response) => {
                // Clear loading state
                setIsLoading(false);

                // Handle success
                toast({
                    position: 'bottom-right',
                    title: 'Dimensions Updated Successfully.',
                    status: "success",
                    duration: 3000, // Duration in milliseconds
                    isClosable: true, // Whether the toast can be closed by the user
                });
                console.log("Product data submitted:", response.data);
            })
            .catch((error) => {
                // Clear loading state
                setIsLoading(false);

                // Handle error
                toast({
                    position: 'bottom-right',
                    title: "Error updating dimensions" + error,
                    status: "error",
                    duration: 3000, // Duration in milliseconds
                    isClosable: true, // Whether the toast can be closed by the user
                });
                console.error("Error submitting product data:", error);
            });
    };


    return (
        <Card mt={4} p={4} backgroundColor="white">
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <Box>
                    <List spacing={2}>
                        <ListItem>
                            <Flex justify="center" alignItems="center">
                                <Input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    mr={2}
                                />
                            </Flex>
                        </ListItem>
                    </List>
                    <Box mb={6}>
                        <List spacing={0}>
                            {suggestions.length > 0 && (
                                <VStack spacing={0} borderRadius={10} align="start" width={"92%"} border="1px solid #e2e8f0" divider={<StackDivider borderColor='gray.200' />}>
                                    {suggestions.map((suggestion, index) => (
                                        <Box key={suggestion.SKU}>
                                            <ListItem
                                                onClick={() => handleSuggestionSelect(suggestion)}
                                                cursor="pointer"
                                                ml={2}
                                                py={2}
                                            >
                                                {suggestion.SKU} | {suggestion.ClientName} |{" "}
                                                {suggestion.EANBarcode}
                                            </ListItem>
                                            {index < suggestions.length - 1 && (
                                                <StackDivider borderColor="gray.200" />
                                            )}
                                        </Box>
                                    ))}
                                </VStack>
                            )}
                        </List>
                    </Box>
                </Box>
                <Flex justify="space-evenly" mt={4}>
                    <Box>
                        <Text fontWeight="bold">SKU:</Text>
                        <Text>{productData.SKU}</Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">Client:</Text>
                        <Text>{productData.ClientName}</Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">UPC/Barcode:</Text>
                        <Text>{productData.EANBarcode}</Text>
                    </Box>
                </Flex>
                <Flex justify="space-evenly" mt={4}>
                    <InputBox
                        title="Weight"
                        unit="KG"
                        value={productData.Weight}
                        onChange={(e) => handleInputChange(e, "Weight")}
                    />
                    <InputBox
                        title="Length"
                        unit="CM"
                        value={productData.Length}
                        onChange={(e) => handleInputChange(e, "Length")}
                    />
                    <InputBox
                        title="Depth"
                        unit="CM"
                        value={productData.Depth}
                        onChange={(e) => handleInputChange(e, "Depth")}
                    />
                    <InputBox
                        title="Height"
                        unit="CM"
                        value={productData.Height}
                        onChange={(e) => handleInputChange(e, "Height")}
                    />
                    <InputBox
                        title="Volume"
                        unit="CM"
                        value={productData.VolumetricSize}
                        readOnly={true}
                    />
                </Flex>
                <Button
                    display={"flex"}
                    colorScheme="teal"
                    variant="solid"
                    mt={4}
                     onClick={() => setIsOpen(true)}
                    isLoading={isLoading} // Enable loading state
                    loadingText="Submitting" // Optional loading text
                    disabled={!productData.SKU || isLoading} // Disable button when there is no SKU or while loading
                    ml={"auto"}
                    mr={"auto"}
                >
                    {isLoading ? "Submitting" : "Submit"}
                    {isLoading && <Spinner size="sm" ml={2} />}
                </Button>

            </Box>
            {/* AlertDialog component */}
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Submission
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to submit?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="teal" onClick={handleSubmit} ml={3}>
                                Submit
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Card>
    );
}
