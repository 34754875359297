import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Button,
  FormControl,
  Switch,
  Input,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import axios from "components/axios/axiosInstance";
import DatePicker from "react-datepicker"; // Make sure to import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import { format } from 'date-fns';
import { constants } from "constants/constants";
import Select from "react-select";

const fields = [
    { name: "System Number", key: "system_number", editable: false },
    { name: "Job Status", key: "job_status", editable: false },
    { name: "3Linx Ticket Number", key: "ticket_number", editable: false },
    { name: "Creation Date", key: "creation_date", editable: false },
    { name: "Warehouse", key: "warehouse", editable: false },
    { name: "Client", key: "client", editable: false },
    { name: "Job Type", key: "job_type", editable: false },
    { name: "Job Name", key: "job_name", editable: false },
    { name: "Job Description", key: "job_description", editable: false },
    { name: "Job Department", key: "job_department", editable: false },
    { name: "Number of Units", key: "number_of_units", editable: false },
    { name: "Job Priority", key: "job_priority", editable: false },
    { name: "Delivery Date", key: "delivery_date", editable: false },
    { name: "Job Billable", key: "job_billable", editable: false },
    { name: "Billing Type", key: "billing_type", editable: false },
    { name: "Billing Rate", key: "billing_rate", editable: false },
    { name: "Seconds per Kit", key: "secs_perkit", editable: false },

    { name: "Job Start Date", key: "job_start_date",  type: "datepicker", editable: true },
   
    { name: "# Completed Units", key: "completed_units", type: "number", editable: true },
    { name: "# Confirm Completed Units", key: "confirm_completed_units", type: "number", editable: true },
    { name: "# QCS Units", key: "qcs_units", type: "number", editable: true },
    { name: "Job Manager", key: "job_manager",type: "select", editable: true },
  

    { name: "Setup Time (Mins)", key: "setup_time", type: "number", editable: true },
    { name: "Management Time (Mins)", key: "management_time", type: "number", editable: true },
    { name: "Cleardown Time (Mins)", key: "cleardown_time", type: "number", editable: true },

    { name: "People Requires to Complete Job", key: "people_on_job", type: "number", editable: true },
    { name: "Actual Job Man Hours", key: "actual_job_man_hours",type: "number",  editable: true },
    { name: "Confirm Actual Job Man Hours", key: "confirm_actual_job_man_hours",type: "number",  editable: true },
    { name: "Stock Material Used", key: "stock_material_used", type: "switch", editable: true },
    { name: "Material Used", key: "material_used", type: "text", editable: true },

    { name: "Job Complete", key: "job_complete",  type: "switch", editable: true },
    { name: "Job Completion Date", key: "job_completion_date", type: "datepicker", editable: true },
    { name: "Job Void", key: "job_void", type: "switch", editable: true },
  
  ];
  

function ViewJob() {
  const [recordDetails, setRecordDetails] = useState({});
  const [editableFields, setEditableFields] = useState({});
  const [editableField, setEditableField] = useState(null);
  const [OpsTeam, setOpsTeam] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false); 
  const toast = useToast();

  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }
    
    const paramsArray = queryString.split("&");
    const params = {};
    
    paramsArray.forEach(param => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });
    
    return params;
  };
  
  const windowUrl = window.location.href;
  const queryParams = getQueryParams(windowUrl);
  const id = queryParams["id"];
  
  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(
          `${constants.apiUrl}/support/get-record-details`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            withCredentials: true,
            params: {
              id,
            },
          }
        );
        
        // Calculate values for confirm fields based on available fields
          const updatedRecordDetails = {
            ...response.data,
            confirm_completed_units: response.data.completed_units,
            confirm_actual_job_man_hours: response.data.actual_job_man_hours,
          };

        setRecordDetails(updatedRecordDetails);
      } catch (error) {
        console.error("Error fetching record details:", error);
      }
    };
    
    fetchRecordDetails();

    const fetchEmployeeOptions = async () => {
      try {
        const response = await axios.get(`${constants.apiUrl}/support/get-job-OpsTeam`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        });
  
        // Assuming the API response provides an array of options with label and value properties
        const options = response.data.map((employee) => ({
          label: employee.employee_name,
          value: employee.short_name,
        }));

        setOpsTeam(options);
      } catch (error) {
        console.error("Error fetching employee options:", error);
      }
    };
  
    fetchEmployeeOptions();
  
  }, [id, updateTrigger]);


  const handleEdit = (key, value) => {
   

    setEditableFields((prevEditableFields) => ({
      ...prevEditableFields,
      [key]: value,
    }));

  };
  
  

  const handleUpdate = async () => {
    try {
      const updatedFields = {};

      // Check if any fields have been edited
      let fieldsEdited = false;

      fields.forEach((field) => {
        if (field.editable && editableFields.hasOwnProperty(field.key)) {
          if (editableFields[field.key] !== recordDetails[field.key]?.value) {
            fieldsEdited = true;
            if (field.type === "switch") {
              updatedFields[field.key] = editableFields[field.key] === -1 ? -1 : 0;
            } else if (field.type === "datepicker") {
              const dateValue = editableFields[field.key];
              updatedFields[field.key] = dateValue instanceof Date ? format(dateValue, 'MM-dd-yyyy') : dateValue;
            } else if (field.type === "number") {
              updatedFields[field.key] = parseFloat(editableFields[field.key]) || 0;
            } else if (field.type === "select") {
              updatedFields[field.key] = editableFields[field.key]?.value || "";
            } else {
              updatedFields[field.key] = editableFields[field.key] || "";
            }
          }
        }
      });

      // If no fields have been edited, don't proceed with the update
      if (!fieldsEdited) {
        return;
      }
      

         
        if (editableFields["actual_job_man_hours"] !== editableFields["confirm_actual_job_man_hours"]) {
          toast({
            position: "bottom-right",
            title: "Error: Actual Job Man Hours and Confirm Actual Job Man Hours must be the same.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return; // Exit the function early if the values are not the same
        }

        if (editableFields["completed_units"] !== editableFields["confirm_completed_units"]) {
          toast({
            position: "bottom-right",
            title: "Error: Completed Units and Confirm Completed Units must be the same.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return; // Exit the function early if the values are not the same
        }
      
      const response = await axios.put(
        `${constants.apiUrl}/support/update-record`,
        {
          id,
          fields: updatedFields,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
          // Update state after successful update
      setEditableFields({});
      setUpdateTrigger(!updateTrigger);

        toast({
          position: "bottom-right",
          title: "Job updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "bottom-right",
          title: "Error updating Job",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating Job:", error);
      toast({
        position: "bottom-right",
        title: "Error updating record",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box mt={14} p={4} borderWidth="1px" borderRadius="lg" shadow="md" backgroundColor="white">
      <SimpleGrid columns={3} spacing={4}>
        {/* Non-editable fields */}
        {fields
          .filter((field) => !field.editable)
          .map((field) => (
            <Box key={field.key}>
              <Text fontWeight="bold">{field.name}:</Text>
              <Text>{recordDetails[field.key]?.value}</Text>
            </Box>
          ))}
      </SimpleGrid>
      <hr style={{ margin: "20px 0" }} /> {/* Horizontal separator */}
      <SimpleGrid columns={2} spacing={4}>
      {/* Editable fields */}
     
      {fields
        .filter((field) => field.editable)
        .map((field) => (
          <Box key={field.key}>
            <Text fontWeight="bold">{field.name}:</Text>
            {field.type === "switch" ? (
              <FormControl display="flex" alignItems="center" mb={4} ml={2} mt={2}>
                <Switch
                 required
                 isChecked={
                  editableFields[field.key] === -1 ||
                   (recordDetails[field.key]?.value === "Yes") // Use existing value if available
                }
                  onChange={(e) => handleEdit(field.key, e.target.checked ? -1 : 0)}
                  size="lg"
                  transform="scale(1.2)"
                />
              </FormControl>
            ) : field.type === "datepicker" ? (
              <FormControl mb={4}>
                <InputGroup size="lg">
                  <Flex align="center">
                    <DatePicker
                      required
                      selected={
                        editableFields[field.key] ||
                        (recordDetails[field.key]?.value &&
                          new Date(recordDetails[field.key]?.value)) || // Use existing value if available
                        new Date()
                      }
                      onChange={(date) => handleEdit(field.key, date)}
                      dateFormat="MM-dd-yyyy"
                      placeholderText={`Select ${field.name.toLowerCase()}`}
                      isClearable
                      customInput={
                        <Input
                          variant="filled"
                          fontSize="sm"
                          size="lg"
                          w="190%"
                          placeholder={`Select ${field.name.toLowerCase()}`}
                        />
                      }
                    />
                  </Flex>
                </InputGroup>
              </FormControl>
            ) : field.type === 'select' ? (
              <FormControl mb={4}>
                <Select
                options={OpsTeam}
                value={OpsTeam.find(option => option.value === (editableFields[field.key]?.value || recordDetails[field.key]?.value))} 
                  onChange={(selectedOption) => handleEdit(field.key, selectedOption)}
                />
              </FormControl>
            ) : (
              <Flex align="center">
                <Input
                  type={field.type}
                  value={editableFields[field.key] !== undefined ? editableFields[field.key] : recordDetails[field.key]?.value || ""}
                  onChange={(e) => handleEdit(field.key, e.target.value)}
                  onBlur={() => setEditableField(null)}
                  onWheel={(e) => {
                    if (field.type === "number") {
                      e.preventDefault(); // Prevent scrolling behavior
                    }
                  }}
                  required
                />
              </Flex>
            )}
          </Box>
        ))}
        </SimpleGrid>
      <Button mt={4} colorScheme="green" onClick={handleUpdate}>
        Update
      </Button>
    </Box>
  );
}

export default ViewJob;
