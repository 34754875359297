import React, { useState, useEffect } from "react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";

import {
  Box,
  Spinner,
  Text,
  Badge,
  Flex,
  Input,
  Button,
  useToast,
  FormLabel,
  FormControl,
  Tooltip
} from "@chakra-ui/react";

import TimelineView from "./Timeline";
import { MdEco, MdHelpOutline } from 'react-icons/md';

export default function TrackingInfo() {

  const toast = useToast();

  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }
  
    const paramsArray = queryString.split("&");
    const params = {};
  
    paramsArray.forEach(param => {
      const [key, value] = param.split("=");
      const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
      params[key] = decodedValue;
    });
  
    return params;
  };
  
  
  const windowUrl = window.location.href;
    
  const queryParams = getQueryParams(windowUrl);
  const trackingNumberParam = queryParams["trackingNumber"];

  const [trackingNumber, setTrackingNumber] = useState(trackingNumberParam ? trackingNumberParam : "");
  const [responseData, setResponseData] = useState(null);
  const [loading, setIsLoading] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Call the API with the provided order number
    axios
      .get(`${constants.apiUrl}/customer/fetch-tracking-details`, { headers, withCredentials: true, params: { trackingNumber: trackingNumber, userId: userDetails.userId } })
      .then((response) => {
        const responseData = response.data;
        const deliveredDate = responseData.deliveredDate;
        if (deliveredDate !== null) {
        
          const options = {
            timeZone: 'Europe/London',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          };
          
          const formattedDeliveredDate = new Intl.DateTimeFormat('en-GB', options).format(new Date(deliveredDate));
          responseData.deliveredDate = formattedDeliveredDate; // Update the formatted date in the response data
        } else {
          responseData.deliveredDate = "-";
        }


        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Tracking details:", error);
        toast({
          position: "top",
          title: "Error",
          description: error.response.data.message || "An error occurred while fetching Tracking Details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        setIsLoading(false);
        setResponseData(null);
      });
  };

  useEffect(() => {
    if (trackingNumberParam) {
      handleFormSubmit({ preventDefault: () => {} }); // Pass an empty object that has preventDefault method
    }
  }, []); // Run only once when component mounts
  

  if (loading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    );
  }


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex direction={{ base: "column", md: "row" }} gridGap="20px">

        <FormControl mt={6} >
          <FormLabel textAlign={"end"}>Enter Tracking Number</FormLabel>

        </FormControl>
        <FormControl mt={4}>
          <Input
            type="text"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            required
            variant="brand"
          />
        </FormControl>
        {/* Add more dropdowns as needed for warehouse, department, client, task type, and billing type */}
        <FormControl mt={4}>
          <Button variant="brand" size='md' onClick={handleFormSubmit}>
            Track
          </Button>

        </FormControl>
        {responseData && (
          <FormControl mt={4}>
            <Button variant="brand" size='md'>
              <a href={responseData.trackingURL} target="_blank" rel="noopener noreferrer">
                Track on {responseData.carrierName}
              </a>
            </Button>
          </FormControl>


        )}
      </Flex>

      {responseData && (
        <Box>
          <Flex justifyContent="space-between" mt={8}>
            <Box>
              <Text fontWeight="bold">Tracking Number:</Text>
              <Text>{responseData.trackingNumber}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Carrier:</Text>
              <Text>{responseData.carrierName}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold" style={{ display: 'flex', alignItems: 'center' }}>
                Distance{" "}

                <Tooltip label="Estimated Distance" fontSize="md" placement="top">
                  <span>
                    <MdHelpOutline />
                  </span>
                </Tooltip>


              </Text>
              <Text ml={2}>{responseData.geoCodingDistance !== null ? `${responseData.geoCodingDistance} Kms` : 'N/A'}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold" style={{ display: 'flex', alignItems: 'center' }}>
                Carbon Emission{" "}
                <Tooltip label="Estimated Carbon Emission" fontSize="md" placement="top" >
                  <span>
                    <MdHelpOutline />
                  </span>
                </Tooltip>
              </Text>
              <Text ml={2} style={{ display: 'flex' }}>
                <MdEco size={20} color="green" /> {responseData.carbonEmission !== null ? `${responseData.carbonEmission} Kg CO2e` : 'N/A'}
              </Text>
            </Box>
            <Box>
              <Text ml={2} fontWeight="bold">Status:</Text>
              <Badge
                colorScheme={
                  responseData.delivered === 1 ? "green" : "red"
                }
              >
                {responseData.delivered === 1 ? "Delivered" : "In Transit"}
              </Badge>
            </Box>
            <Box>
              <Text fontWeight="bold">Delivered Date:</Text>
              <Text>{`${responseData.deliveredDate}`}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Client:</Text>
              <Text >{responseData.clientCode}</Text>

            </Box>
            <Box>
              <Text fontWeight="bold">Order Number:</Text>
              <Text >{responseData.orderNumber}</Text>

            </Box>
          </Flex>

          <TimelineView events={responseData.events} />
        </Box>
      )}
    </Box>

  );
}
