import React, { useState, useEffect, useRef } from "react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Button,
  Wrap,
  WrapItem,
  Spinner,
  Text,
  Badge,
  IconButton,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Input,
  Select,
  Avatar,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { FaEye, FaTrash } from "react-icons/fa";

export default function SubmittedClaims() {
  const [loading, setIsLoading] = useState(false);
  const [clientFilter, setClientFilter] = useState([]);
  const [carrierFilter, setCarrierFilter] = useState([]);
  const [selectedClient, setSelectedClient] = useState(''); // Add this state
  const [selectedCarrier, setSelectedCarrier] = useState(''); // Add this state
  const [selectedTab, setSelectedTab] = useState("All"); // Default tab is "All"
  const [searchText, setSearchText] = useState(""); // State for search input
  const [claimType, setClaimType] = useState("All"); // State for claim type dropdown
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [claimToDelete, setClaimToDelete] = useState(null);

  const [page, setPage] = useState(1); // Initialize the page number to 1
  const [hasMore, sethasMore] = useState(true);
  // Create a state object to store claims for each tab
  const [tabClaims, setTabClaims] = useState({
    All: [],
    Pending: [],
    "In Progress": [],
    Approved: [],
    Credited: [],
    Rejected: [],
  });

  const toast = useToast();

  const cancelRef = useRef();
  const alertDialogRef = useRef();
  const openConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const fetchData = async (status, claimType, client, carrier) => {
    try {

      console.log('hasMore',hasMore);
      if (!hasMore || loading) {
        // No more data to fetch or a request is already in progress
        return;
      }

      setIsLoading(true); // Set loading state to true

      const token = localStorage.getItem("accessToken");
   
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const params = {
        status: status,
        claimType: claimType,
        page,
      };

      if (client) {
        params.client = client;
      }

      if (carrier) {
        params.carrier = carrier;
      }

      const response = await axios.get(`${constants.apiUrl}/support/submitted-claims`, {
        headers,
        withCredentials: true,
        params,
      });

      // Get the existing data for the selected tab
      const existingData = tabClaims[status];

      // Check for duplicates based on trackingNumber and type, and only append new data
      const newData = response.data.filter((newClaim) => {
        return !existingData.some(
          (existingClaim) =>
            existingClaim.trackingNumber === newClaim.trackingNumber &&
            existingClaim.type === newClaim.type
        );
      });


      // Update the claims state with the new data based on the tab
      setTabClaims((prevTabClaims) => ({
        ...prevTabClaims,
        [status]: [...existingData, ...newData],
      }));

      // Check if there is more data
      if (response.data.length === 0 || response.data.length < 8) {
        sethasMore(false); // No more data to fetch
      } else {
        setPage((prevPage) => prevPage + 1); // Increment the page if there is more data
      }

    } catch (error) {
      console.error("Error fetching claims:", error);
    } finally {
      setIsLoading(false); // Reset loading state whether the request was successful or not
    }
  };


  const fetchFilterData = async (claimType) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${constants.apiUrl}/support/fetch-claim-filters`, {
        headers,
        withCredentials: true,
        params: { claimType: claimType },
      });

      setClientFilter(response.data.clients);
      setCarrierFilter(response.data.carriers);

    } catch (error) {
      console.error("Error fetching filters:", error);

    }
  };

  useEffect(() => {
    setIsLoading(true);
    setPage(1); // Reset page to 1 when switching tabs
    fetchData(selectedTab, claimType, selectedClient, selectedCarrier);
  }, [selectedTab, claimType, selectedClient, selectedCarrier]);

  useEffect(() => {
    fetchFilterData(claimType);
  }, [claimType]);



  // Handler for clicking the "Delete" icon
  const handleDeleteClick = (claim) => {
    setClaimToDelete(claim); // Store the claim to be deleted
    openConfirmation();
  };

  // Handler for confirming the deletion
  const handleConfirmDelete = async () => {
    closeConfirmation();
    if (claimToDelete) {
      try {
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.delete(`${constants.apiUrl}/admin/delete-claim`, {
          headers,
          data: { trackingNumber: claimToDelete.trackingNumber, type: claimToDelete.type },
        });

        console.log("Claim deleted successfully:", response.data);

        // Check the status code in the response and show the appropriate toast message
        if (response.status === 200) {
          toast({
            position: "bottom-right",
            title: "Claim Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }


        // After successful deletion, you may want to fetch the updated list of claims
        fetchData(selectedTab, claimType);
      } catch (error) {
        console.error("Error deleting claim:", error);
        if (error.response.status === 403) {
          toast({
            position: "bottom-right",
            title: "Forbidden: You do not have permission to delete the claim",
            description: error.response.data.message, // Access the error message here
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            position: "bottom-right",
            title: "Error: Failed to delete the claim: " + error.response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }


      }
    }
  };


  function RenderClaims({ claims }) {
    const borderColor = useColorModeValue("white", "gray.800");

    return (
      <InfiniteScroll
        dataLength={claims.length} // Use the actual length of your claims array
        next={() => fetchData(selectedTab, claimType, selectedClient, selectedCarrier)}
        hasMore={hasMore}
        loader={<Flex height="10vh" alignItems="center" justifyContent="center">
          <Spinner size="sm" thickness="4px" color="green.500" />
        </Flex>}
        // endMessage={
        //   <p style={{ textAlign: 'center' }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
        style={{ overflowX: 'hidden', overflowY: 'hidden' }}
      >
        <Wrap spacing={4} style={{ whiteSpace: 'nowrap' }}>
          {claims.length === 0 ? (
            <Text>No submitted claims found.</Text>
          ) : (
            claims.map((claim) => (
              <WrapItem key={claim.claimKey}>
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  shadow="md"
                  bg={borderColor}
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  width="250px"
                >
                  <Box mt="auto" alignSelf="flex-end">
                    <Link
                      to={`/support/view-claim?trackingNumber=${claim.trackingNumber}&type=${claim.type}`}
                      style={{ textDecoration: "none" }}
                    >
                      <IconButton
                        icon={<FaEye />}
                        aria-label="View"
                        colorScheme="blue"
                        variant="ghost"
                        size="sm"
                      />
                    </Link>
                    <IconButton
                      icon={<FaTrash />}
                      aria-label="Delete"
                      colorScheme="red"
                      variant="ghost"
                      size="sm"
                      onClick={() => handleDeleteClick(claim)}
                    />
                  </Box>
                  <Box mb={2}>
                    <Text fontWeight="bold">Tracking Number:</Text>
                    <Text>{claim.trackingNumber}</Text>
                  </Box>
                  <Box mb={2} display="flex" alignItems="center" gap={2}>
                    <Badge
                      colorScheme={
                        claim.type === "Damaged" ? "orange" : 
                        claim.type === "Late" ? "yellow" : 
                        "blue"
                      }
                      
                    >
                      {claim.type}
                    </Badge>
                    <Badge
                      colorScheme={claim.status === "Approved" || claim.status === "Credited" ? "green" : "red"}
                    >
                      {claim.status}
                    </Badge>
                  </Box>
                  {claim.raisedBy && (
                    <Box mb={2} display="flex" alignItems="center" gap={2}>
                      <Avatar size="sm" name={claim.raisedBy} />
                      <Text>{claim.raisedBy}</Text>
                    </Box>
                  )}
                </Box>
              </WrapItem>
            ))
          )}

        </Wrap>

      </InfiniteScroll>
    );

  }

  const handleTabChange = (index) => {
    const statuses = ["All", "Pending", "In Progress", "Approved", "Credited", "Rejected"];
    const selectedStatus = statuses[index];
    setSelectedTab(selectedStatus);
    setPage(1); // Reset page to 1 when switching tabs
    sethasMore(true); // Reset hasMore to true when switching tabs
    setTabClaims((prevTabClaims) => ({
      ...prevTabClaims,
      [selectedStatus]: [], // Clear the existing data for the selected tab
    }));
  };
  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(1); // Reset page to 1 when filters change
    sethasMore(true); // Reset hasMore to true when switching tabs
    setTabClaims((prevTabClaims) => {
      // Clear the existing data for all tabs
      const clearedTabClaims = {};
      Object.keys(prevTabClaims).forEach((tab) => {
        clearedTabClaims[tab] = [];
      });
      return clearedTabClaims;
    });
    fetchData(selectedTab, claimType, selectedClient, selectedCarrier);
  };
  
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box mb={4} display="flex" alignItems="center">
        <Input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          flex="4"
        />
        <Select
          ml={2}
          value={claimType}
          onChange={(e) => {
            setClaimType(e.target.value);
            handleFilterChange();
          }}
          flex="2"
        >
          <option value="All">All</option>
          <option value="Damaged">Damaged</option>
          <option value="Lost">Lost</option>
          <option value="Late">Late</option>
        </Select>
        {/* Dropdown for Clients */}
        <Select
          ml={2}
          value={selectedClient}
          flex="2"
          onChange={(e) => {
            setSelectedClient(e.target.value);
            handleFilterChange();
          }}
        >
          <option value="">All Clients</option>
          {clientFilter.map((client) => (
            <option key={client} value={client}>
              {client}
            </option>
          ))}
        </Select>

        {/* Dropdown for Carriers */}
        <Select
          ml={2}
          flex="2"
          value={selectedCarrier}
          onChange={(e) => {
            setSelectedCarrier(e.target.value);
            handleFilterChange();
          }}
        >
          <option value="">All Carriers</option>
          {carrierFilter.map((carrier) => (
            <option key={carrier} value={carrier}>
              {carrier}
            </option>
          ))}
        </Select>
      </Box>

      <AlertDialog
        isOpen={isConfirmationOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeConfirmation}
        motionPreset="slideInBottom"
        finalFocusRef={cancelRef}
        initialFocusRef={alertDialogRef}
      >
        <AlertDialogOverlay />

        <AlertDialogContent ref={alertDialogRef}>
          <AlertDialogHeader>Delete Claim</AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to delete this claim?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={closeConfirmation}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={() => handleConfirmDelete()} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>


      <Tabs
        isFitted
        variant="enclosed"
        colorScheme="green"
        onChange={handleTabChange}
      >
        <TabList>
          <Tab>All</Tab>
          <Tab>Pending</Tab>
          <Tab>In Progress</Tab>
          <Tab>Approved</Tab>
          <Tab>Credited</Tab>
          <Tab>Rejected</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {loading ? (
              <Flex height="100vh" alignItems="center" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="green.500" />
              </Flex>
            ) : (
              <RenderClaims claims={tabClaims[selectedTab]} />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Flex height="100vh" alignItems="center" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="green.500" />
              </Flex>
            ) : (
              <RenderClaims claims={tabClaims[selectedTab]} />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Flex height="100vh" alignItems="center" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="green.500" />
              </Flex>
            ) : (
              <RenderClaims claims={tabClaims[selectedTab]} />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Flex height="100vh" alignItems="center" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="green.500" />
              </Flex>
            ) : (
              <RenderClaims claims={tabClaims[selectedTab]} />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Flex height="100vh" alignItems="center" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="green.500" />
              </Flex>
            ) : (
              <RenderClaims claims={tabClaims[selectedTab]} />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Flex height="100vh" alignItems="center" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="green.500" />
              </Flex>
            ) : (
              <RenderClaims claims={tabClaims[selectedTab]} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
