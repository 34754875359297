import axios from 'axios';

// Function to retrieve the latest showactive value
const getShowActiveValue = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  return userDetails ? userDetails.showactive : null;
};

// Create Axios instance with default showactive value
const createAxiosInstance = (showactive) => {
  // Create a new Axios instance
  const axiosInstance = axios.create();

  // Add a request interceptor to add showactive to all requests
  axiosInstance.interceptors.request.use(
    (config) => {
      // Add showactive to the request headers
      config.headers['showactive'] = showactive;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor to handle 401 responses
  axiosInstance.interceptors.response.use(
    (response) => {
      // If the response status is not 401, return the response as-is
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized response here
        // You can redirect to the login page or perform any other action
        window.location.href = '/login'; // Replace with your login page URL
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

// Retrieve the latest showactive value
const showactive = getShowActiveValue();

// Create Axios instance with the retrieved showactive value
const axiosInstance = createAxiosInstance(showactive);

export default axiosInstance;
