import React, { useState, useEffect } from "react";
import {
    Box,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Button,
    Flex,
    useToast,
    Heading,
    Switch
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";

export default function UpdateProfile() {
    const getQueryParams = (url) => {
        const queryString = url.split("?")[1];
        if (!queryString) {
            return {};
        }

        const paramsArray = queryString.split("&");
        const params = {};

        paramsArray.forEach(param => {
            const [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
        });

        return params;
    };

    const windowUrl = window.location.href;
    const [generatedPassword, setGeneratedPassword] = useState("");
    const [isCustomer, setIsCustomer] = useState(false);
    const [activeClientsToggle, setActiveClientsToggle] = useState(true);

    const queryParams = getQueryParams(windowUrl);
    const userId = queryParams["id"];
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        // email: "",
        password: "",
    });
   
    const toast = useToast();

    // Fetch user data based on the provided userId
    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Fetch user data
        axios
            .get(`${constants.apiUrl}/customer/fetch-profile`, {
                headers,
                withCredentials: true,
            })
            .then((response) => {
                const { data } = response;
                const userRoles = data.role.split(",");
                setIsCustomer(userRoles.includes("Customer"));
                setUserData(data);

            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
                // Handle errors (e.g., show an error message)
            });

        setActiveClientsToggle(userDetails.showactive === true);

    }, [userId]);

    // Function to handle toggle switch change
    const handleToggleChange = () => {
        // Update the activeClientsToggle state
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));

        setActiveClientsToggle(!activeClientsToggle);
        console.log(userDetails);
        // Update the showactive value in userDetails
        userDetails.showactive = !activeClientsToggle;
        console.log(userDetails);
        // Update the userDetails in localStorage
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
    };

    const handleUpdate = () => {
        if (!userData.firstName || !userData.lastName) {
            // Display a toast or any other error handling
            toast({
                position: "bottom-right",
                title: "Please fill all required fields.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return; // Stop the update process
        }
        const token = localStorage.getItem("accessToken");
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        // Make an API call to update the user data
        axios
            .put(`${constants.apiUrl}/customer/profile-update`, { ...userData, password: generatedPassword }, { headers, withCredentials: true })
            .then((response) => {
                // Handle success (e.g., show a success message)
                console.log("User data updated successfully");
                toast({
                    position: "bottom-right",
                    title: "User data updated successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                console.error("Error updating user data:", error);
                // Handle errors (e.g., show an error message)
                toast({
                    position: "bottom-right",
                    title: "Error updating user data.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    };


    const generateRandomPassword = () => {
        const randomPassword = Math.random().toString(36).substring(2, 10);
        setGeneratedPassword(randomPassword);
    };
    return (
        <Box
            pt={{ base: "130px", md: "80px", xl: "80px" }}
            backgroundColor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >

            <SimpleGrid columns={1} gap="20px" mb="20px" w="50%">
                <Heading>Update Profile</Heading>
                {/* First Name */}
                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input
                        placeholder="First Name"
                        value={userData.firstName}
                        onChange={(e) =>
                            setUserData({ ...userData, firstName: e.target.value })
                        }
                        required
                    />
                </FormControl>

                {/* Last Name */}
                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                        placeholder="Last Name"
                        value={userData.lastName}
                        onChange={(e) =>
                            setUserData({ ...userData, lastName: e.target.value })
                        }
                        required
                    />
                </FormControl>

                {/* Email */}
                {/* <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Email"
            value={userData.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            required
          />
        </FormControl> */}

                <FormControl>
                    <FormLabel>Change Password</FormLabel>
                    <Flex align="center">
                        <Input
                            type="text"
                            value={generatedPassword}
                            onChange={(e) => setGeneratedPassword(e.target.value)}
                            required
                            width="50%"
                        />
                        <Button
                            colorScheme="green"
                            variant="brand"
                            onClick={generateRandomPassword}
                            ml="2"
                        >
                            Generate New Password
                        </Button>
                    </Flex>
                </FormControl>
                {!isCustomer && (<FormControl>
                    <Flex align="center">
                    <FormLabel>Show Active Clients</FormLabel>
                    <Switch
                        isChecked={activeClientsToggle}
                        reversed={true}
                        fontSize="sm"
                        mb="6px"
                        variant="main"
              colorScheme="brandScheme"
                        onChange={() => handleToggleChange(activeClientsToggle)} // Attach the onChange handler
                    />
                    </Flex>
                </FormControl>)}


                {/* Submit Button */}
                <Button
                    variant="brand"
                    size="md"
                    onClick={handleUpdate}
                >
                    Update
                </Button>
            </SimpleGrid>
        </Box>
    );
}
