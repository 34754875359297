import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import GenericLayout from 'layouts/generic';
import CustomerLayout from 'layouts/customer';
import SupportLayout from 'layouts/support';
import OperationsLayout from 'layouts/operations';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import PrivateRoute from "./routes/privateRoute";
import PrivateRouteGeneric from "./routes/privateRouteGeneric";
import routes from "../src/routes";

const filteredRoutes = routes.filter((route) => {
    return route.layout === "/generic"; // Filter only routes with layout "/generic"
});

ReactDOM.render(
    <ChakraProvider theme={theme}>
        <React.StrictMode>
            <HashRouter>
                <Switch>
                    <Route path={`/auth`} component={AuthLayout} />
                    {filteredRoutes.map(route => (
                        <PrivateRouteGeneric
                            key={route.path}
                            path={`${route.layout}${route.path}`}
                            layout={route.layoutComponent} // Pass layout prop
                            component={route.component}
                            requiredRoles={route.accessRole.split(",").map(role => role.trim())} // Convert access roles string to array
                        />
                    ))}
                    <PrivateRoute path={`/admin`} component={AdminLayout} requiredRoles={['Admin']} />
                    <PrivateRoute path={`/customer`} component={CustomerLayout} requiredRoles={['Customer']} />
                    <PrivateRoute path={`/support`} component={SupportLayout} requiredRoles={['Support']} />
                    <PrivateRoute path={`/operations`} component={OperationsLayout} requiredRoles={['Operations']} />
                    <Redirect from='/' to='/auth' />
                </Switch>
            </HashRouter>
        </React.StrictMode>
    </ChakraProvider>,
    document.getElementById('root')
);
