import React, { useState, useEffect } from 'react';
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
import {
    Box,
    Icon,
    SimpleGrid,
    Heading,
    useColorModeValue,
    Flex,
    Spinner
} from '@chakra-ui/react';
import Card from "components/card/Card";
import {
    MdLocalShipping,
    MdAssignment,
    MdGavel,
    MdDirectionsBoat,
    MdDoneAll,
    MdTimeline,
    MdTimer,
    MdEco,
    MdStorage,
    MdDns,
    MdConfirmationNumber
} from "react-icons/md";
import MiniStatistics from "views/customer/dashboardStats/MiniStatistics";
import IconBox from "components/icons/IconBox";
import Select from 'react-select';

const DashboardStats = () => {
    const [loading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

    const dateOptions = [
        { value: '1', label: 'Last 1 day' },
        { value: '7', label: 'Last 7 days' },
        { value: '30', label: 'Last 30 days' },
        { value: '90', label: 'Last 90 days' },
        { value: '180', label: 'Last 180 days' }
      ];

    const [selectedOption, setSelectedOption] = useState(dateOptions[1]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem("accessToken");
                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                const response = await axios.get(
                    `${constants.apiUrl}/customer/dashboard-stats`,
                    {
                        headers,
                        withCredentials: true,
                        params: { days: selectedOption['value'] },
                    }
                );

                setData(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching tracking stats:", error);
                setIsLoading(false);
            }
        };

        fetchData(); // Call the fetchData function inside useEffect
    }, [selectedOption]); // Empty dependency array to run the effect only once

  

    const handleDaysChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        // Add any additional logic you need for handling the change in selected days
        console.log(`Selected: ${selectedOption.value}`);
    };

    if (loading) {
        return (
            <Flex height="100vh" alignItems="center" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="green.500" />
            </Flex>
        );
    }

    return (
        <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            mt={16}
        >
            <Box p={4}>
                <Flex justifyContent="flex-end">
                    <Select
                        value={selectedOption}
                        onChange={handleDaysChange}
                        options={dateOptions}
                        placeholder="Select a date range"
                    />
                </Flex>
                <Heading mb={4} size="md">In Progress</Heading>
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 5 }}
                    gap="20px"
                >

                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdLocalShipping}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Orders In Progress"
                        value={data.ordersInProgress}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdAssignment}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Tasks In Progress"
                        value={data.taskCount}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdGavel}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Claims In Progress"
                        value={data.claimsCount}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdDirectionsBoat}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Shipments In Transit"
                        value={data.shipmentsInTransit}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdConfirmationNumber}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="My Open Tickets"
                        value={data.ticketsCount}
                    />
                </SimpleGrid>
                <Heading mb={4} size="md">Orders</Heading>
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    gap="20px"
                >
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdLocalShipping}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Orders Fulfilled"
                        value={data.ordersFulfilled}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdDoneAll}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Orders QC'd"
                        value={data.qcOrderCount}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdTimeline}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Order SLA"
                        value={0.00}
                    />
                </SimpleGrid>
                <Heading mb={4} size="md">Shipments</Heading>
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 4 }}
                    gap="20px"
                >
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdDirectionsBoat}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Shipments In Transit"
                        value={data.shipmentsInTransit}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdDoneAll}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Shipments Delivered"
                        value={data.shipmentsDelivered}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdTimer}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Shipments Delayed"
                        value={data.shipmentsDelayed}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdEco}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Estimated CO2"
                        value={`${data.totalCarbonEmission} Kg CO2e`}
                    />
                </SimpleGrid>
                <Heading mb={4} size="md">Inventory</Heading>
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    gap="20px"
                >
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdStorage}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Total Units in Stock"
                        value={data.totalStockUnits}
                    />
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w="56px"
                                h="56px"
                                bg={boxBg}
                                icon={
                                    <Icon
                                        w="32px"
                                        h="32px"
                                        as={MdDns}
                                        color={brandColor}
                                    />
                                }
                            />
                        }
                        name="Total SKUs"
                        value={data.totalSKUs}
                    />
                </SimpleGrid>
            </Box>
        </Card>
    );
}

export default DashboardStats;
