import React, { useEffect, useState } from "react";
import axios from "components/axios/axiosInstance";
import {
  Box,
  Text,
  Heading,
  Flex,
  SimpleGrid,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { constants } from "constants/constants";
import ComplexTable from "views/support/profitabilityReport/components/ComplexTable";

function ProfitabilityReport() {
  const [clientsWithWarehouses, setClientsWithWarehouses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const queryParamsExist = new URLSearchParams(location.search).toString() !== "";

  useEffect(() => {
    const fetchClientsWithWarehouses = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const params = {};
        if (searchQuery.length >= 2) {
          params.search = searchQuery;
        }
        const response = await axios.get(`${constants.apiUrl}/support/clients-with-warehouses`, { headers, params, withCredentials: true });
        setClientsWithWarehouses(response.data);
      } catch (error) {
        console.error("Error fetching clients with warehouses:", error);
      }
    };

    fetchClientsWithWarehouses();
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {queryParamsExist ? (
        <Box p={2}>
          <SimpleGrid gap="20px" mb="20px">
            <ComplexTable />
          </SimpleGrid>
        </Box>
      ) : (
        <>
      <InputGroup mt={4} mb={4} width="100%">
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          pr="4.5rem"
        />
      </InputGroup>
      <Flex flexWrap="wrap" gap={4}>
     
        {clientsWithWarehouses.map((client) => (
          <Flex
            key={client.Client}
            bg="white"
            borderRadius="md"
            p={4}
            boxShadow="lg"
            flexDirection="column"
            flexGrow={1}
            flexBasis="calc(25% - 16px)" // Adjusted flex basis for 4 columns per row
            mb={4}
          >
            <Link
              to={`/support/profitablility-report?client=${encodeURIComponent(
                client.Client
              )}`}
            >
              <Heading textAlign="center" size="md" mb={2}>
                {client.Client}
              </Heading>
            </Link>
            <Flex flexWrap="wrap" justifyContent="space-between">
  {Object.keys(client.Warehouses).map((warehouseName) => {
    const invoicedAmount = client.Warehouses[warehouseName];

    return (
      <Link
        key={warehouseName}
        to={`/support/profitablility-report?warehouse=${encodeURIComponent(
          warehouseName
        )}&client=${encodeURIComponent(client.Client)}`}
      >
        <Box display="flex" flexDirection="column" mb={2} cursor="pointer">
          <Text fontWeight="bold" fontSize="lg">
            {warehouseName}
          </Text>
          <Text mt={1}>€ {invoicedAmount !== null ? invoicedAmount.toFixed(2) : '0.00'}</Text>


        </Box>
      </Link>
    );
  })}
</Flex>
          </Flex>
        ))}
      </Flex>
      </>
      )}
    </Box>
  );
}

export default ProfitabilityReport;
