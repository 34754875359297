import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Flex,
  useToast
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css"; // Import your custom CSS for DatePicker
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";

export default function LateForm({ orderItems, trackingNumber }) {
  const toast = useToast();
  const [formData, setFormData] = useState({
    dateOfDelivery: null,
  });

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dateOfDelivery: date,
    });
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("accessToken");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Fixed content type typo
    };
  
    // Ensure formData.dateOfDelivery is not null before proceeding
    if (!formData.dateOfDelivery) {
      toast({
        position: "top",
        title: "Please select a date of delivery",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Exit early if dateOfDelivery is not set
    }
  // Format the date explicitly as "MM-dd-yyyy" before sending it
  const dateFormat = formData.dateOfDelivery.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  // Split the date by slashes, reverse it, and join with hyphens
  const formattedDate = dateFormat.split("/").join("-");
    axios
      .post(
        `${constants.apiUrl}/customer/add-late-claim`,
        {
          trackingNumber: trackingNumber,
          deliveryDate: formattedDate,
          userId: userDetails.userId,
        },
        { headers, withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          toast({
            position: "top",
            title: "Claim Submitted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            position: "top",
            title: "Error Submitting Claim, Please try again! ",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        const errorMessage = error.response.data.message || "Error Submitting Claim, Please try again!";
        toast({
          position: "top",
          title: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error submitting claim:", error);
      });
  };
  

  return (
    <Box p={6} backgroundColor={"white"}>
      <FormControl isRequired>
        <FormLabel>Date of Delivery</FormLabel>
        <InputGroup w={"150%"}> 
          <DatePicker
             minDate={new Date()}
            // showYearDropdown
            // scrollableMonthYearDropdown
            selected={formData.dateOfDelivery}
            onChange={handleDateChange}
            dateFormat="MM-dd-yyyy"
            className="custom-datepicker" // Add a class name for custom styling
          />
        </InputGroup>
      </FormControl>

      <Flex justify="flex-end" mt={4}>
        <Button variant="brand" onClick={handleSubmit}>
          Submit Claim
        </Button>
      </Flex>
    </Box>
  );
}
