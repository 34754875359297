import React, { useState, useEffect } from 'react';
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Input,
    Flex,
    Text,
    Divider,
    Button,
    useToast,
    Icon,
    InputRightAddon,
    InputGroup,
    Heading
} from '@chakra-ui/react';
import Card from "components/card/Card";
import { FaCheck, FaDownload, FaUpload } from 'react-icons/fa';


const getDateLabel = (date) => {
    const currentDate = new Date();
    const dayInMillis = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const today = new Date(currentDate);
    const yesterday = new Date(currentDate.getTime() - dayInMillis); // Using getTime() to avoid modification of currentDate
    const tomorrow = new Date(currentDate.getTime() + dayInMillis); // Using getTime() to avoid modification of currentDate

    if (isSameDate(date, today)) {
        return ' - Today';
    } else if (isSameDate(date, yesterday)) {
        return ' - Yesterday';
    } else if (isSameDate(date, tomorrow)) {
        return ' - Tomorrow';
    }
};

const isSameDate = (date1, date2) => {
    return date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();
};

// Function to determine the status of an accordion based on its date
const getAccordionStatus = (date, foundItem) => {
    const today = new Date().setHours(0, 0, 0, 0);

    // Convert date to a timestamp with hours set to 0 for comparison
    const dateTimestamp = new Date(date).setHours(0, 0, 0, 0);

    if (date < today) {
        return 'lightgray'; // Past date
    } else if (dateTimestamp === today) {
        return 'lightgray'; // Today
    } else if (foundItem.estimateConfirmed === true) {
        return 'lightgreen';
    } else {
        return 'yellow';
    }
};

const DayItem = ({ index, date, projections, handleInputChange, handleSubmit, previousYear }) => {
    const [isTicked, setIsTicked] = useState(false);

    const foundItem = projections.find(item => item.date === date);
    const backgroundColor = getAccordionStatus(new Date(date), foundItem);
    const isDisabled = backgroundColor === 'lightgray';

    return (
        <Box key={date} mb={4} backgroundColor={backgroundColor} p={"8px"}>
            <Text fontWeight="bold">
                Day {index + 1} - {date ? new Date(date).toDateString() : ''}{getDateLabel(new Date(date))}
            </Text>
            <Divider mt={2} />
            <Flex mt={2}>
                <Text mt={2} mr={4}>
                    Number of Units:
                </Text>
                <Box mr={4}>
                    <Text mt={2} mr={4}>
                        {`PY${previousYear} => `}

                        {foundItem ? foundItem.py : 0}
                    </Text>
                </Box>
                <Box mr={4}>
                    <InputGroup>
                        <Input
                            type="number"
                            min={0}
                            placeholder="Estimated"
                            value={foundItem ? foundItem.estimated : ''}
                            onChange={(e) => handleInputChange(e, date, 'estimated')}
                            isDisabled={isDisabled} // Disable input if background is lightgray
                        />
                        <InputRightAddon
                            children={
                                <Icon
                                    as={FaCheck}
                                    boxSize={5}
                                    color={foundItem?.estimateConfirmed ? 'green.500' : 'grey'}
                                    cursor={isDisabled ? 'not-allowed' : 'pointer'} // Change cursor to not-allowed if disabled
                                    onClick={(e) => {
                                        if (!isDisabled) {
                                            setIsTicked(prevIsTicked => {
                                                const newIsTicked = !prevIsTicked;
                                                handleInputChange(e, date, 'estimateConfirmed', newIsTicked);
                                                return newIsTicked;
                                            });
                                        }
                                    }}
                                    _hover={{ color: isDisabled ? 'grey' : 'green.500' }} // Disable hover if input is disabled
                                />
                            }
                        />
                    </InputGroup>
                </Box>

                <Box>
                    <Text mt={2} mr={4}>
                        {`Actual => `}
                        {foundItem ? foundItem.actual : 0}
                    </Text>
                </Box>
            </Flex>
        </Box>
    );
};

const AddReturnsProjections = () => {

    const [projections, setProjections] = useState([]);
    const [quarters, setQuarters] = useState({});
    const [previousYear, setPreviousYear] = useState('');
    const [defaultQuarterIndex, setDefaultQuarterIndex] = useState(null);
    const [defaultWeekIndex, setDefaultWeekIndex] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const toast = useToast();
    const projectionType = 'Return';
    useEffect(() => {
        // Fetch data from your API endpoint
        fetchData();
    }, []); // Run this effect only once on component mount

    const fetchData = async () => {
        try {
            const userDetails = JSON.parse(localStorage.getItem("userDetails"));
            const token = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.get(`${constants.apiUrl}/customer/returns-projection-dates?userId=${userDetails.userId}`, {
                headers,
                withCredentials: true,
            });

            setProjections(response.data.projections); // Assuming the API response is an array
            setPreviousYear(response.data.previousYear);
            setQuarters(response.data.quarters);

            // Calculate tomorrow's date and set time to 00:00:00.000Z
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setUTCHours(0, 0, 0, 0);
            const tomorrowDateString = tomorrow.toISOString(); // Get the ISO string of the date

            // Find the default quarter and week indices
            let foundQuarterIndex = null;
            let foundWeekIndex = null;

            Object.entries(response.data.quarters).forEach(([quarter, weeks], quarterIndex) => {
                Object.entries(weeks).forEach(([week, days], weekIndex) => {
                    if (days.includes(tomorrowDateString)) {
                        if (foundQuarterIndex === null) {
                            foundQuarterIndex = quarterIndex;
                        }
                        if (foundWeekIndex === null) {
                            foundWeekIndex = weekIndex;
                        }
                    }
                });
            });

            setDefaultQuarterIndex(foundQuarterIndex);
            setDefaultWeekIndex(foundWeekIndex);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSubmit = (updatedProjections) => {
        const token = localStorage.getItem("accessToken");
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));

        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Fixed content type typo
        };

        axios
            .post(
                `${constants.apiUrl}/customer/add-returns-projections`,
                {
                    projections: updatedProjections,
                    userId: userDetails.userId,
                },
                { headers, withCredentials: true }
            )
            .then((response) => {
                if (response.status === 200) {
                    toast({
                        position: "top",
                        title: "Returns Projections Submitted Successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        position: "top",
                        title: "Error Submitting Returns Projections, Please try again! ",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                const errorMessage = error.response.data.message || "Error Submitting Returns Projections, Please try again!";
                toast({
                    position: "top",
                    title: errorMessage,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                console.error("Error submitting Returns Projections:", error);
            });
    };


    // Function to handle changes in input fields
    const handleInputChange = (e, date, type, isTickedValue) => {
        const { value } = e.target;
        // Update projections state based on input changes
        const updatedProjections = projections.map((item) => {
            const itemDate = item.date;
            if (itemDate === date) {
                const updatedItem = {
                    ...item,
                    estimateConfirmed: isTickedValue ? 1 : 0, // Update estimateConfirmed based on the ticked value
                };

                if (!isTickedValue) {
                    updatedItem[type] = parseInt(value); // Update type only if isTickedValue is false
                }
                return updatedItem;
            }
            return item;
        });
        setProjections(updatedProjections);

        if (isTickedValue) {
            handleSubmit(updatedProjections);
        }
    };

    // Function to handle CSV download
    const handleDownloadSampleCSV = async () => {
        try {

            const token = localStorage.getItem('accessToken');

            // Make GET request to download-sample-csv endpoint
            const response = await axios.get(`${constants.apiUrl}/customer/download-sample-projections-csv`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    type: projectionType,
                },
                // responseType: 'blob', // Important to set responseType to blob for file download
            });

            // Prepare CSV content
            const csvContent = prepareCSVContent(response.data);

            // Create a Blob object for the CSV content
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${projectionType}-Sample-Data.csv`);
            link.setAttribute('target', '_blank'); // Force download without prompt

            // Append the anchor element to the body
            document.body.appendChild(link);

            // Simulate a click on the anchor to trigger the download
            link.click();

            // Clean up: remove the anchor element after download
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading sample CSV:', error);
            toast({
                title: "Error Downloading Sample CSV",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    // Function to prepare CSV content from projections data
    const prepareCSVContent = (projections) => {
        // Define CSV headers
        const headers = ['Date', 'PY - Info Only', 'Estimated - Enter Data'];

        // Convert projections data to CSV rows
        const rows = projections.map(item => {
            return `${item.Date},${item.PY},${item.Estimated}`;
        });

        // Concatenate headers and rows
        const csvContent = headers.join(',') + '\n' + rows.join('\n');
        return csvContent;
    };

    const handleUploadCSV = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        const token = localStorage.getItem("accessToken");
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));

        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userDetails.userId);

        setIsUploading(true);

        axios.post(`${constants.apiUrl}/customer/upload-projections-csv`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
            params: {
                type: projectionType,
            },
        })
            .then((response) => {
                setIsUploading(false);
                if (response.status === 200) {
                    toast({
                        position: "top",
                        title: "CSV Uploaded Successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        position: "top",
                        title: "Error Uploading CSV, Please try again!",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }

            })
            .catch((error) => {
                setIsUploading(false);
                const errorMessage = error.response.data.message || "Error Uploading CSV, Please try again!";
                toast({
                    position: "top",
                    title: errorMessage,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                console.error("Error uploading CSV:", error);
            })
            .finally(() => {
                setIsUploading(false);
                setSelectedFile(null);
                fetchData();
            });
    };


    return (
        <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            mt={16}
        >
            <Box p={4}>
                <Box p={4}>
                    <Heading as="h2" size="lg" mb={4}>
                        Upload via CSV file
                    </Heading>
                    <Text mb={4}>
                        Instructions: First, download the sample data. Then, update the estimated column in the CSV and upload it back.
                    </Text>
                    <Flex mb={4} justifyContent="space-between" alignItems="center">
                        <Button leftIcon={<FaDownload />} colorScheme="blue" onClick={handleDownloadSampleCSV}>
                            Download Sample CSV
                        </Button>
                        <Box>
                            <Button
                                leftIcon={<FaUpload />}
                                colorScheme="green"
                                isLoading={isUploading}
                            >
                                <input
                                    type="file"
                                    accept=".csv"
                                    onChange={handleUploadCSV}
                                    style={{
                                        position: 'absolute',
                                        opacity: 0,
                                        cursor: 'pointer',
                                    }}
                                />
                                Upload CSV
                            </Button>
                            {selectedFile && (
                                <Flex mt={2} alignItems="center">
                                    <Text mr={2}>Selected File: {selectedFile.name}</Text>
                                </Flex>
                            )}
                        </Box>
                    </Flex>
                </Box>
                <Heading as="h4" size="md" mb={4} textAlign="center">
                    OR
                </Heading>
                {quarters && projections && (
                    <Accordion
                        allowMultiple
                        index={defaultQuarterIndex !== null ? [defaultQuarterIndex] : []}
                        onChange={(indices) => {
                            if (indices.length > 0) {
                                setDefaultQuarterIndex(indices[0]); // Set the first index only
                                setDefaultWeekIndex(null); // Reset week index when changing quarter
                            } else {
                                setDefaultQuarterIndex(null); // If no quarter is open, set to null
                            }
                        }}
                    >
                        {/* Render accordion items for each quarter */}
                        {Object.entries(quarters).map(([quarter, weeks], quarterIndex) => (
                            <AccordionItem key={quarter}>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            Quarter {quarter}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    {/* Render accordion items for each week */}
                                    <Accordion
                                        allowMultiple
                                        index={defaultQuarterIndex === quarterIndex && defaultWeekIndex !== null ? [defaultWeekIndex] : []}
                                        onChange={(indices) => {
                                            if (indices.length > 0) {
                                                setDefaultWeekIndex(indices[0]); // Set the first index only
                                            } else {
                                                setDefaultWeekIndex(null); // If no week is open, set to null
                                            }
                                        }}
                                    >
                                        {Object.entries(weeks).map(([week, days], weekIndex) => (
                                            <AccordionItem key={week}>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left">
                                                            Week {week}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    {/* Render accordion items for each day */}
                                                    {days.map((date, index) => (
                                                        <DayItem
                                                            key={index}
                                                            index={index}
                                                            date={date}
                                                            projections={projections}
                                                            handleInputChange={handleInputChange}
                                                            handleSubmit={handleSubmit}
                                                            previousYear={previousYear}
                                                        />
                                                    ))}
                                                </AccordionPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                )}
            </Box>
        </Card>
    );

};

export default AddReturnsProjections;
