import React from "react";
import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import ComplexTable from "views/support/projectionsReports/components/ComplexTable";

function ProjectionsReports() {
  return (
    <Box p={6}>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid gap="20px" mb="20px">
            <ComplexTable />
          </SimpleGrid>
        </Box>
    
    </Box>
  );
}

export default ProjectionsReports;
