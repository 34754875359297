import React, { useState } from "react";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
import {
    Box,
    Flex,
    Input,
    Button,
    useToast,
    SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from 'react-router-dom';
export default function AddAddressLabels() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        carrier: '',
        organisation: '',
        countryCode: '',
        street: '',
        locality: '',
        town: '',
        county: '',
        postcode: '',
        accountUsername: '',
        accountPassword: '',
    });

    const toast = useToast();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        // Validate form data
        const requiredFields = ['carrier', 'countryCode', 'street', 'county', 'postcode', 'accountUsername', 'accountPassword'];
    
        for (const field of requiredFields) {
            if (!formData[field]) {
                toast({
                    position: 'bottom-right',
                    title: `Please enter a valid ${field}.`,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }
        }
    
        // Set loading state
        setIsLoading(true);
    
        // Assuming you have an API endpoint to submit the address
        const token = localStorage.getItem("accessToken");
        const headers = {
            Authorization: `Bearer ${token}`,
        };
    
        axios
            .post(`${constants.apiUrl}/support/submit-address`, formData, {
                headers,
                withCredentials: true,
            })
            .then((response) => {
                // Clear loading state
                setIsLoading(false);
    
                // Handle success
                toast({
                    position: 'bottom-right',
                    title: 'Address submitted successfully.',
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                console.log("Address submitted:", response.data);
            })
            .catch((error) => {
                // Clear loading state
                setIsLoading(false);
    
                // Handle error
                toast({
                    position: 'bottom-right',
                    title: "Error submitting address: " + error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                console.error("Error submitting address:", error);
            }) .finally(() => {
                history.push('/support/list-addresses');
            });
    };
    

    return (
        <Box
            pt={{ base: "130px", md: "80px", xl: "80px" }}
            backgroundColor="white"
            display="flex"
            flexDirection="column"
            alignItems="center"
            minHeight="100vh"
        >
            <Card mt={4} p={4} backgroundColor="white" width="600px">
                <SimpleGrid columns={2} spacing={4}>
                    <Input
                        name="carrier"
                        placeholder="Carrier"
                        value={formData.carrier}
                        onChange={handleChange}
                    />
                    <Input
                        name="organisation"
                        placeholder="Organisation"
                        value={formData.organisation}
                        onChange={handleChange}
                    />
                    <Input
                        name="countryCode"
                        placeholder="Country Code"
                        value={formData.countryCode}
                        onChange={handleChange}
                    />
                    <Input
                        name="street"
                        placeholder="Street"
                        value={formData.street}
                        onChange={handleChange}
                    />
                    <Input
                        name="locality"
                        placeholder="Locality"
                        value={formData.locality}
                        onChange={handleChange}
                    />
                    <Input
                        name="town"
                        placeholder="Town"
                        value={formData.town}
                        onChange={handleChange}
                    />
                    <Input
                        name="county"
                        placeholder="County"
                        value={formData.county}
                        onChange={handleChange}
                    />
                    <Input
                        name="postcode"
                        placeholder="Postcode"
                        value={formData.postcode}
                        onChange={handleChange}
                    />
                    <Input
                        name="accountUsername"
                        placeholder="Account Username"
                        value={formData.accountUsername}
                        onChange={handleChange}
                    />
                    <Input
                        name="accountPassword"
                        placeholder="Account Password"
                        type="password"
                        value={formData.accountPassword}
                        onChange={handleChange}
                    />
                </SimpleGrid>
                <Flex justify="center" mt={4}>
                    <Button
                        onClick={handleSubmit}
                        colorScheme="brand"
                        isLoading={isLoading}
                        loadingText="Submitting..."
                        width="100%"
                    >
                        Submit
                    </Button>
                </Flex>
            </Card>
        </Box>
    );
}
