import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
  Box,
  Link as ChakraLink
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp, FiDownload } from "react-icons/fi";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";

export default function ComplexTable() {
  const columnsData = [
    {
      Header: "TRACKING NO.",
      accessor: "trackingNumber",
     },
    {
        Header: "CARRIER NAME",
        accessor: "carrierName",
    },
    {
        Header: "CLIENT",
        accessor: "clientCode",
    },
    {
        Header: "COURIER SERVICE NAME",
        accessor: "courierServiceName",
    },
    {
        Header: "LAST STATUS",
        accessor: "lastStatus",
    },
    {
      Header: "EXPECTED DELIVERY DATE",
      accessor: "expectedDeliveryDate",
    },
    {
      Header: "DELIVERED DATE",
      accessor: "deliveredDate",
    },
    {
      Header: "ORDER NUMBER",
      accessor: "orderNumber",
    },
    {
      Header: "DELAYED BY",
      accessor: "delayedBy",
  },
  {
    Header: "Co2 Emission (Kg CO2e)",
    accessor: "carbonEmission",
},
{
  Header: "Distance (Km)",
  accessor: "geoCodingDistance",
},
];

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");


  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }
  
    const paramsArray = queryString.split("&");
    const params = {};
  
    paramsArray.forEach(param => {
      const [key, value] = param.split("=");
      const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
      params[key] = decodedValue;
    });
  
    return params;
  };
  
  
  // const windowUrl = window.location.href;
    
  // const queryParams = getQueryParams(windowUrl);
  // const warehouseParam = queryParams["warehouse"];
  // const dataForParam = queryParams["dataFor"];
  // const courierServiceNameParam = queryParams["courierServiceName"];
  // const clientParam = queryParams["client"];
  // const carrierNameParam = queryParams["carrierName"];
  // const startDateParam = queryParams["startDate"];
  // const endDateParam = queryParams["endDate"];

 

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };
  
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
      setSearchQuery(value);
  };
  

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
    
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
    
     
      const queryParams = getQueryParams(window.location.href);
    
      const response = await axios.get(
        `${constants.apiUrl}/admin/shipment-data-list`,
        {
          headers,
          withCredentials: true,
          params: {
            page,
            pageSize,
            ...queryParams,
          },
        }
      );
      const records = response.data.records;
      records.forEach((record) => {
        // Updating expectedDeliveryDate

        const options = {
          timeZone: 'Europe/London',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        };

        if (record.expectedDeliveryDate !== null) {
            record.expectedDeliveryDate =  new Intl.DateTimeFormat('en-GB', options).format(new Date(record.expectedDeliveryDate));
        }
      
        // Updating deliveredDate
        if (record.deliveredDate !== null) {
          record.deliveredDate = new Intl.DateTimeFormat('en-GB', options).format(new Date(record.deliveredDate));

        }

      });
      setTableData(records);
      setTotalPages(response.data.totalPages);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    }finally{
      setIsDataLoading(false);
    }
  };

   // Fetch data for the initial page
  
  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForPage(currentPage, pageSize, searchQuery);
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    }
  }, [currentPage, pageSize, searchQuery]);

  
  const exportToCSV = () => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const queryParams = getQueryParams(window.location.href);
      axios
        .get(`${constants.apiUrl}/support/shipment-data-list`, {
          headers,
          withCredentials: true,
          params: {
            // page,
            // pageSize,
            ...queryParams,
            exportCSV: true,
          },
        })
        .then((response) => {
          console.log(response.data);
          const data = response.data.records.map((item) => ({
            ...item,
            id: item.id,
          }));

          const separator = ",";
          const csvContent =
            columnsData.map((column) => column.Header).join(separator) +
            "\n" +
            data
              .map((row) =>
                columnsData
                  .map((column) => {
                    let cell = row[column.accessor];
                    if (cell === null || cell === undefined) {
                      cell = "";
                    } else if (
                      typeof cell === "object" &&
                      cell.hasOwnProperty("value")
                    ) {
                      cell = cell.value;
                    }
                    cell = cell.toString().replace(/"/g, '""');
                    cell = cell.toString().replace(/€/g, "");
                    if (
                      cell.includes(separator) ||
                      cell.includes("\n") ||
                      cell.includes('"')
                    ) {
                      cell = `"${cell}"`;
                    }
                    return cell;
                  })
                  .join(separator)
              )
              .join("\n");

          const csvBlob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
          });
          const csvURL = window.URL.createObjectURL(csvBlob);

          // Generate file name
        const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
     
        let fileName = `shipment-report-${timestamp}.csv`;
                   
        const queryParamString = Object.entries(queryParams)
        .filter(([_, value]) => value !== "") // Filter out empty values
        .map(([_, value]) => `${value}`)
        .join("_");
        if(queryParamString){
          fileName = `shipment-report-${queryParamString}-${timestamp}.csv`;
        }
        
          const tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", fileName);
          tempLink.click();

          resolve(); // Resolve the Promise after successful export
        })
        .catch((error) => {
          console.error("Error exporting csv data:", error);
          reject(error); // Reject the Promise if there's an error
        });
    });
  };

  // Function to handle button click event
  const handleExportButtonClick = () => {
    exportToCSV()
      .then(() => {
        console.log("CSV export completed successfully");
      })
      .catch((error) => {
        console.error("CSV export failed:", error);
      });
  };


 
  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
    manualPagination: true,
    pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
          
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

           


            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
             <Icon
              as={FiDownload}
              onClick={handleExportButtonClick}
              variant="brand"
              w={"4em"}
              h={"1.5em"}
              marginTop={"5px"}
            >
              Export
            </Icon>
          </Flex>
          <Box overflowX="auto" overflowY="auto" maxWidth="100%" >
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            style={{ overflowX: "auto", overflowY: "auto"  }}
          >
            <Thead   position="sticky"
                      top="0"
                      bg="white"
                      zIndex="sticky"
                      borderBottomWidth="1px"
                      boxShadow="md"
                      p="5px 25px"
            >
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                      minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                      whiteSpace="normal"
                      wordBreak="break-word"
                    
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()} overflowY="auto" maxHeight="300px">
            {page.length === 0 ? (
              <Tr>
                <Td colSpan={columnsData.length} textAlign="center">
                  No data available.
                </Td>
              </Tr>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                       let cellContent = cell.value;

                       // Check if the cell corresponds to the "TRACKING NO." column
                       if (cell.column.id === "trackingNumber") {
                           // If it is, wrap the cell content in a Chakra UI Link component
                           cellContent = (
                               <ChakraLink href={`/#/support/track-your-parcel?trackingNumber=${cell.value}`} color="blue.500">
                                   {cell.value}
                               </ChakraLink>
                           );
                       } else {
                           // For other columns, use default cell rendering
                           cellContent = (
                               <Text color={textColor} fontSize="sm" fontWeight="700">
                                   {cell.value}
                               </Text>
                           );
                       }
                      

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          maxH="30px !important"
                          py="8px"
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {cellContent}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            )}
          </Tbody>
          </Table>
          </Box>
          
          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
