import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Button,
  FormControl,
  Switch,
  Input,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import axios from "components/axios/axiosInstance";
import DatePicker from "react-datepicker"; // Make sure to import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import { format } from 'date-fns';
import { constants } from "constants/constants";

const fields = [
    { name: "System Number", key: "system_number", editable: false },
    { name: "Job Status", key: "job_status", editable: false },
    { name: "3Linx Ticket Number", key: "ticket_number", editable: false },
    { name: "Creation Date", key: "creation_date", editable: false },
    { name: "Warehouse", key: "warehouse", editable: false },
    { name: "Client", key: "client", editable: false },
    { name: "Job Type", key: "job_type", editable: false },
    { name: "Job Name", key: "job_name", editable: false },
    { name: "Job Description", key: "job_description", editable: false },
    { name: "Job Department", key: "job_department", editable: false },
    { name: "Number of Units", key: "number_of_units", editable: false },
    { name: "Job Priority", key: "job_priority", editable: false },
    { name: "Delivery Date", key: "delivery_date", editable: false },
    { name: "Job Billable", key: "job_billable", editable: false },
    { name: "Billing Type", key: "billing_type", editable: false },
    { name: "Billing Rate", key: "billing_rate", editable: false },
    { name: "Seconds per Kit", key: "secs_perkit", editable: false },
    { name: "Setup Time", key: "setup_time", editable: false },
    { name: "Management Time", key: "management_time", editable: false },
    { name: "Cleardown Time", key: "cleardown_time", editable: false },
    { name: "Job Start Date", key: "job_start_date", editable: false },
    { name: "Job Complete", key: "job_complete", editable: false },
    { name: "Completed Units", key: "completed_units", editable: false },
    { name: "QCS Units", key: "qcs_units", editable: false },
    { name: "Job Manager", key: "job_manager", editable: false },
    { name: "Stock Material Used", key: "stock_material_used", editable: false },
    { name: "Job Completion Date", key: "job_completion_date", editable: false },
    { name: "People on Job", key: "people_on_job", editable: false },
    { name: "Actual Job Man Hours", key: "actual_job_man_hours", editable: false },
    { name: "Material Used", key: "material_used", editable: false },
    { name: "Material Cost", key: "material_cost", type: "number", editable: true },
    { name: "Support Sheet", key: "support_sheet", type: "text", editable: true },
    { name: "Invoiced", key: "invoiced", type: "switch", editable: true },
    { name: "Invoiced Amount", key: "invoiced_amount", type: "number", editable: true },
    { name: "Invoice Date", key: "invoice_date", type: "datepicker", editable: true },
    { name: "Job Void", key: "job_void", type: "switch", editable: true },
    // Add more fields from the model
  ];
  

function ViewJob() {
  const [recordDetails, setRecordDetails] = useState({});
  const [editableFields, setEditableFields] = useState({});
  const [editableField, setEditableField] = useState(null);
  const [invoicedDate, setInvoicedDate] = useState(new Date()); // State for DatePicker
  const [isBillable, setIsBillable] = useState(0); // State for Switch
  const [isJobVoid, setIsJobVoid] = useState(0); // State for Switch

  const toast = useToast();

  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }
    
    const paramsArray = queryString.split("&");
    const params = {};
    
    paramsArray.forEach(param => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });
    
    return params;
  };
  
  const windowUrl = window.location.href;
  const queryParams = getQueryParams(windowUrl);
  const id = queryParams["id"];
  
  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(
          `${constants.apiUrl}/support/get-record-details`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            withCredentials: true,
            params: {
              id,
            },
          }
        );
        
        
     // Check if the values exist and set the states accordingly
     if (response.data.invoice_date.value !== "" && response.data.invoice_date.value !== null) {
      const dateValue = response.data.invoice_date.value;
      let parsedDate;
    
      if (dateValue.includes('/')) {
        const dateParts = dateValue.split('/');
        const month = dateParts[0];
        const day = dateParts[1];
        const year = dateParts[2];
        parsedDate = new Date(`${year}-${month}-${day}`);
      } else {
        parsedDate = new Date(dateValue);
      }
    
      setInvoicedDate(parsedDate);
    }

      
      if (response.data.invoiced.value === "Yes") {
        setIsBillable(-1);
      }
      if (response.data.job_void.value === "Yes") {
        setIsJobVoid(-1);
      }
      console.log('recordDetails', recordDetails);
        setRecordDetails(response.data);
      } catch (error) {
        console.error("Error fetching record details:", error);
      }
    };
    
    fetchRecordDetails();
  }, [id]);


  const handleEdit = (key, value) => {
    setEditableFields((prevEditableFields) => ({
      ...prevEditableFields,
      [key]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const updatedFields = {};
      fields.forEach((field) => {
        if (field.type === "switch") {
          if (field.key === "invoiced") {
            updatedFields[field.key] = isBillable === -1 ? -1 : 0;
          } else if (field.key === "job_void") {
            updatedFields[field.key] = isJobVoid === -1 ? -1 : 0;
          }
        } else if (field.type === "text" || field.type === "number") {
          updatedFields[field.key] = editableFields[field.key] || recordDetails[field.key]?.value;
        } else if (field.type === "datepicker") {
          updatedFields[field.key] = editableFields[field.key] || format(invoicedDate, 'MM-dd-yyyy');
        }
      });
      
  
      const response = await axios.put(
        `${constants.apiUrl}/support/update-record`,
        {
          id,
          fields: updatedFields,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
        toast({
          position: "bottom-right",
          title: "Job updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "bottom-right",
          title: "Error updating Job",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating record:", error);
      toast({
        position: "bottom-right",
        title: "Error updating record",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  

  return (
    <Box mt={14} p={4} borderWidth="1px" borderRadius="lg" shadow="md" backgroundColor={"white"}>
      <SimpleGrid columns={3} spacing={4}>
        {/* Non-editable fields */}
        {fields
          .filter((field) => !field.editable)
          .map((field) => (
            <Box key={field.key}>
              <Text fontWeight="bold">{field.name}:</Text>
              <Text>{recordDetails[field.key]?.value}</Text>
            </Box>
          ))}
      </SimpleGrid>
      <hr style={{ margin: "20px 0" }} /> {/* Horizontal separator */}
      <SimpleGrid columns={2} spacing={4}>
        {/* Editable fields */}
        {console.log(isBillable)}
        {fields
          .filter((field) => field.editable)
          .map((field) => (
            <Box key={field.key}>
            <Text fontWeight="bold">{field.name}:</Text>
            {field.type === "switch" && field.key === "invoiced" ? (
              <FormControl display="flex" alignItems="center" mb={4} ml={2}>
                <Switch
                  isChecked={isBillable === -1}
                  onChange={(e) => setIsBillable(e.target.checked ? -1 : 0)}
                  size="lg"
                  transform="scale(1.2)"
                />
              </FormControl>
            ) : field.type === "switch" && field.key === "job_void" ? (
              <FormControl display="flex" alignItems="center" mb={4} ml={2}>
                <Switch
                  isChecked={isJobVoid === -1}
                  onChange={(e) => setIsJobVoid(e.target.checked ? -1 : 0)}
                  size="lg"
                  transform="scale(1.2)"
                />
              </FormControl>
            ) : field.type === "datepicker" ? (
              <FormControl mb={4}>
                <InputGroup size="lg">
                  <Flex align="center">
                    <DatePicker
                      selected={invoicedDate}
                      onChange={(date) => setInvoicedDate(date)}
                      dateFormat='MM-dd-yyyy'
                      placeholderText={`Select ${field.name.toLowerCase()}`}
                      isClearable
                      customInput={
                        <Input
                          variant="filled"
                          fontSize="sm"
                          size="lg"
                          w="190%"
                          placeholder={`Select ${field.name.toLowerCase()}`}
                        />
                      }
                    />
                  </Flex>
                </InputGroup>
              </FormControl>
            ) : (
              <Flex align="center">
                <Input
                  type={field.type}
                  value={editableFields[field.key] !== undefined ? editableFields[field.key] : recordDetails[field.key]?.value}
                  onChange={(e) => handleEdit(field.key, e.target.value)}
                  onBlur={() => setEditableField(null)}
                />
              </Flex>
            )}
          </Box>
          
          ))}
      </SimpleGrid>
      <Button mt={4} colorScheme="green" onClick={handleUpdate}>
        Update
      </Button>
    </Box>
  );

}

export default ViewJob;
