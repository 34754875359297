import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function EmployeeDashboard() {
  useEffect(() => {
    if (!window.fsWidgetConfig) {
      const fsWidgetConfig = {
        id: "331d206b-3897-4f5d-9da9-90d9a58f40b9",
      };
      window.fsWidgetConfig = fsWidgetConfig;

      const widgetScript = document.createElement("script");
      widgetScript.type = "module";
      widgetScript.src = "https://cdn.freshstatus.io/widget/index.js";
      document.head.appendChild(widgetScript);
    }
  }, []);

  try {
    return (
      <div style={{ marginTop: "6px" }}>
        <Helmet>
          {/* You don't need to declare fsWidgetConfig again here */}
        </Helmet>

        <iframe
          title="Notion Page"
          src="https://www.notioniframe.com/notion/288esdz0xeh"
          width="100%"
          height="1000px" // Adjust the height as needed
          allowFullScreen
          scrolling="no"
          style={{ border: "none", overflow: "hidden" }}
        ></iframe>
      </div>
    );
  } catch (error) {
    console.error("An error occurred:", error);
    return null; // Render nothing or a friendly message to the user
  }
}
