import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css"; // Import your custom CSS for DatePicker

export default function ComplexTable() {
  const columnsData = [
    {
      Header: "QC TYPE",
      accessor: "qcType",
    },
    {
      Header: "QC DATE",
      accessor: "createdAt",
    },
    {
      Header: "WAREHOUSE",
      accessor: "warehouse",
    },

    {
      Header: "CLIENT",
      accessor: "client",
    },

    {
      Header: "ORDER/REFERENCE",
      accessor: "orderReference",
    },
    {
      Header: "QC'D By",
      accessor: "updatedBy",
    },
    {
      Header: "COMMENTS",
      accessor: "comments",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
  ];

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [qcOptions, setQCOptions] = useState({
    qcTypeOptions: [],
    warehouseOptions: [],
    clientOptions: [],
    statusOptions: [],
    dateFilter: "",
  });

  const [selectedFilters, setSelectedFilters] = useState({
    qcType: "",
    warehouse: "",
    client: "",
    status: "",
  });
  const handleDateChange = (date) => {
    setSelectedFilters({ ...selectedFilters, dateFilter: date });
  };
  // Define event handlers for filter options
  const handleWarehouseChange = (value) => {
    setSelectedFilters({ ...selectedFilters, warehouse: value });
  };

  const handleClientChange = (value) => {
    setSelectedFilters({ ...selectedFilters, client: value });
  };

  const handleStatusChange = (value) => {
    setSelectedFilters({ ...selectedFilters, status: value });
  };

  const handleQCTypeChange = (value) => {
    setSelectedFilters({ ...selectedFilters, qcType: value });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/support/qc-data-list`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
            warehouse: selectedFilters.warehouse,
            client: selectedFilters.client,
            status: selectedFilters.status,
            qcType: selectedFilters.qcType,
            dateFilter: selectedFilters.dateFilter
          },
        }
      );

      const data = response.data.qcListData;
      setTableData(data);
      setTotalPages(response.data.totalPages);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    // Fetch all dropdown options
    // Assuming you have an API endpoint to submit product data
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${constants.apiUrl}/support/qc-dropdowns`, { headers })
      .then((response) => {
        const {
          qcTypeOptions,
          warehouseOptions,
          clientOptions,
          statusOptions,
        } = response.data;
        setQCOptions({
          qcTypeOptions: qcTypeOptions.length > 0 ? qcTypeOptions : [],
          warehouseOptions: warehouseOptions.length > 0 ? warehouseOptions : [],
          clientOptions: clientOptions.length > 0 ? clientOptions : [],
          statusOptions: statusOptions.length > 0 ? statusOptions : [],
        });
      })
      .catch((error) =>
        console.error("Error fetching dropdown options:", error)
      );
  }, []);
  // Fetch data for the initial page

  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForPage(currentPage, pageSize, searchQuery);
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    }
  }, [currentPage, pageSize, searchQuery, selectedFilters]);

  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
      manualPagination: true,
      pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              QC Report
            </Text>

            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
          </Flex>
          <Flex
            px="25px"
            justify="space-between"
            mb="10px"
            align="center"
            flexWrap="unset" // Allow wrapping of filter elements
          >
            {/* Warehouse filter */}
            <Select
              /* Select component props */
              onChange={(e) => handleWarehouseChange(e.target.value)}
              value={selectedFilters.warehouse}
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            >
              <option value="">Select Warehouse</option>
              {qcOptions.warehouseOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {/* Client filter */}
            <Select
              /* Select component props */
              onChange={(e) => handleClientChange(e.target.value)}
              value={selectedFilters.client}
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            >
              <option value="">Select Client</option>
              {qcOptions.clientOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {/* QC Type filter */}
            <Select
              /* Select component props */
              onChange={(e) => handleQCTypeChange(e.target.value)}
              value={selectedFilters.qcType}
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            >
              <option value="">Select QC Type</option>
              {qcOptions.qcTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {/* Status filter */}
            <Select
              /* Select component props */
              onChange={(e) => handleStatusChange(e.target.value)}
              value={selectedFilters.status}
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            >
              <option value="">Select Status</option>
              {qcOptions.statusOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {/* Date Picker */}
            <DatePicker
              //  minDate={new Date()}
              maxDate={new Date()}
              placeholderText="Filter By Date"
              // showYearDropdown
              // scrollableMonthYearDropdown
              selected={selectedFilters.dateFilter}
              onChange={handleDateChange}
              dateFormat="dd-MM-yyyy"
              className="custom-datepicker" // Add a class name for custom styling
              mr="10px" // Add margin to create space between filters
              mb="10px" // Add margin to create space between rows
            />
          </Flex>

          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            style={{ overflowX: "auto" }}
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let cellContent = cell.value;

                        if (cell.column.Header === "View") {
                          cellContent = (
                            <Link
                              to={`/support/view-job?id=${cell.value}`} // Change this route to match your actual route
                              style={{
                                display: "inline-block",
                                padding: "6px 12px",
                                backgroundColor: "green",
                                color: "white",
                                textDecoration: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                              }}
                            >
                              View
                            </Link>
                          );
                        } else {
                          cellContent = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        }

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {cellContent}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>

          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
