import React, { useState, useEffect } from "react";
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
import {
    Box,
    Flex,
    Input,
    Button,
    useToast,
    Select,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from 'react-router-dom';

export default function GenerateDPDLabels() {
    const [isLoading, setIsLoading] = useState(false);
    const [numParcels, setNumParcels] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const toast = useToast();
    const history = useHistory();

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const token = localStorage.getItem("accessToken");
                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                const addressRes = await axios.get(`${constants.apiUrl}/support/list-addresses`, { headers, withCredentials: true });

                setAddresses(addressRes.data.data);

                const warehouseRes = await axios.get(`${constants.apiUrl}/support/warehouse`, { headers, withCredentials: true });
                setWarehouses(warehouseRes.data);
            } catch (error) {
                toast({
                    position: 'bottom-right',
                    title: "Error fetching options: " + error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                console.error("Error fetching options:", error);
            }
        };

        fetchOptions();
    }, [toast]);

    const handleGenerateLabels = async () => {
        const num = parseInt(numParcels, 10);
        if (isNaN(num) || num <= 0) {
            toast({
                position: 'bottom-right',
                title: "Please enter a valid number of parcels.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (!selectedAddress) {
            toast({
                position: 'bottom-right',
                title: "Please select an address.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (!selectedWarehouse) {
            toast({
                position: 'bottom-right',
                title: "Please select a warehouse.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);

        const token = localStorage.getItem("accessToken");
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        try {
            const response = await axios.post(`${constants.apiUrl}/support/generate-dpd-labels`, {
                numParcels: num,
                addressId: selectedAddress,
                warehouse: selectedWarehouse,
            }, {
                headers,
                responseType: 'blob',
                withCredentials: true,
            });
            console.log(response.headers);
            const timestamp = new Date().toISOString().replace(/[:.-]/g, ''); // Remove special characters for filename

            const filename = `dpdlabel_${timestamp}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setIsLoading(false);
            toast({
                position: 'bottom-right',
                title: 'Labels Generated Successfully.',
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            history.push('/support/dpd-labels-list');
        } catch (error) {
            setIsLoading(false);
            toast({
                position: 'bottom-right',
                title: "Error generating labels: " + error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error("Error generating labels:", error);
        }
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}
        backgroundColor="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh">
            <Card mt={4} p={4} backgroundColor="white" width="700px">
                <Flex direction="column" align="center">
                    <Input
                        placeholder="Enter Number of Parcels"
                        value={numParcels}
                        onChange={(e) => setNumParcels(e.target.value)}
                        type="number"
                        mb={4}
                        width="100%"
                    />
                      <Select
                        placeholder="Select ShipFrom Warehouse"
                        value={selectedWarehouse}
                        onChange={(e) => setSelectedWarehouse(e.target.value)}
                        mb={4}
                        width="100%"
                    >
                        {warehouses.map((warehouse) => (
                            <option key={warehouse.warehouse_id} value={warehouse.warehouse_id}>
                                {warehouse.warehouse_id}
                            </option>
                        ))}
                    </Select>
                    <Select
                        placeholder="Select ShipTo Address"
                        value={selectedAddress}
                        onChange={(e) => setSelectedAddress(e.target.value)}
                        mb={4}
                        width="100%"
                    >
                        {addresses.map((address) => (
                            <option key={address.id} value={address.id}>{address.carrier} - {address.address}</option>
                        ))}
                    </Select>
                    <Button
                        onClick={handleGenerateLabels}
                        colorScheme="brand"
                        isLoading={isLoading}
                        loadingText="Generating..."
                        width="100%"
                    >
                        Generate Labels
                    </Button>
                </Flex>
            </Card>
        </Box>
    );
}
