/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports

import React, { useState, useEffect } from "react";
import {
    Box,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Button,
   
    Flex,
    useToast,
} from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
import Select from 'react-select';


export default function AddCustomer() {
    const [generatedPassword, setGeneratedPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState(["Customer"]);
    const [mintsoftClientCode, setMintsoftClientCode] = useState("");
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouse, setWarehouse] = useState([]);
    const toast = useToast();
    const history = useHistory();
    // Fetch data for dropdowns (example API endpoints)
    useEffect(() => {
      const token = localStorage.getItem("accessToken");
      const headers = {
          Authorization: `Bearer ${token}`,
      };
      // Fetch warehouse data
      axios.get(`${constants.apiUrl}/support/warehouse`, { headers, withCredentials: true }).then((response) => {
          // Set warehouse data to state
          setWarehouseData(response.data);
      });
    }, []);
    const handleRegister = () => {

    if (!firstName || !lastName || !email || !generatedPassword || !role || !mintsoftClientCode) {
      // Display a toast or any other error handling
      toast({
        position: "bottom-right",
        title: "Please fill all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Stop the registration process
    }


      const userData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: generatedPassword,
        role: role.join(','),
        mintsoftClientCode: mintsoftClientCode,
        warehouse: warehouse.join(',')
      };
  
      axios
        .post(`${constants.apiUrl}/register`, userData) // Make a POST request using Axios
        .then((response) => {
          
          if (response.status === 200) {
            // Registration successful
            console.log("Registration successful");
            toast({
              position: 'bottom-right',
              title: 'Account created.',
              description: "We've created your account for you.",
              status: "success",
              duration: 3000, // Duration in milliseconds
              isClosable: true, // Whether the toast can be closed by the user
            });

  

              // Reset form fields
              setFirstName("");
              setLastName("");
              setEmail("");
              setMintsoftClientCode("");
              setGeneratedPassword("");
              setRole([]);
              setWarehouse([]);

              history.push('/admin/customer-list');
            
          } 
        })
        .catch((error) => {
          console.error("Error creating user :", error);
          if(error.response.status === 409){
            toast({
              position: 'bottom-right',
              title: "User with the same email already exists",
              status: "info",
              duration: 3000, // Duration in milliseconds
              isClosable: true, // Whether the toast can be closed by the user
            });
            
          }else{
            toast({
              position: 'bottom-right',
              title: "Error creating user" + error,
              status: "error",
              duration: 3000, // Duration in milliseconds
              isClosable: true, // Whether the toast can be closed by the user
            });
          }
     
        });

    };


      const handleWarehouseChange = (selectedOptions) => {
        const selectedWarehouses = selectedOptions.map(option => option.value);
        setWarehouse(selectedWarehouses); // Update the 'warehouse' state
    };
    
      
    const generateRandomPassword = () => {
        const randomPassword = Math.random().toString(36).substring(2, 10);
        setGeneratedPassword(randomPassword);
    };

    return (
        <Box
            pt={{ base: "130px", md: "80px", xl: "80px" }}
            backgroundColor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <SimpleGrid columns={1} gap="20px" mb="20px" w="50%">
                {/* First Name */}
                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                </FormControl>

                {/* Last Name */}
                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
                </FormControl>

                {/* Email */}
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </FormControl>

                <FormControl>
                    <FormLabel>Mintoft Client Code</FormLabel>
                    <Input placeholder="Mintoft Client Code" value={mintsoftClientCode} onChange={(e) => setMintsoftClientCode(e.target.value)} required/>
                </FormControl>

                {/* Password */}
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Flex align="center">
                        <Input
                            type="text"
                            value={generatedPassword}
                            onChange={(e) => setGeneratedPassword(e.target.value)}
                            required
                            width="50%"
                        />
                        <Button
                            colorScheme="green"
                            variant="brand"
                            onClick={generateRandomPassword}
                            ml="2"
                        >
                            Generate Password
                        </Button>
                    </Flex>
                </FormControl>
                    <FormControl>
                    <FormLabel>Warehouse</FormLabel>
                    <Select
                          isMulti
                          placeholder="Select Warehouse"
                          value={warehouse.map(warehouseValue => ({ value: warehouseValue, label: warehouseValue }))} // Use the 'warehouse' state here
                          onChange={handleWarehouseChange}
                          options={warehouseData.map(warehouse => ({ value: warehouse.warehouse_id, label: warehouse.warehouse_id }))}
                      />
                    </FormControl>
                {/* Submit Button */}
                <Button variant="brand" size="md" onClick={handleRegister}>Add Support Member</Button>
            </SimpleGrid>
        </Box>
    );
}


