import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
  Box,
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp, FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker"; // Import DatePicker from react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";
import { format } from "date-fns";

export default function ComplexTable() {
  const columnsData = [
    {
      Header: "System Number",
      accessor: "systemNumber",
    },
    {
      Header: "3Linx Ticket Number",
      accessor: "freshdeskTicketNumber",
    },
    {
      Header: "Client",
      accessor: "client",
    },
    {
      Header: "Job Name",
      accessor: "jobName",
    },
    {
      Header: "Job Type",
      accessor: "jobType",
    },
    {
      Header: "Billable?",
      accessor: "billable",
    },
    {
      Header: "Job Complete",
      accessor: "jobComplete",
    },
    {
      Header: "Invoiced?",
      accessor: "invoiced",
    },
    {
      Header: "Delivery Date",
      accessor: "deliveryDate",
    },
    {
      Header: "Job Start Date",
      accessor: "jobStartDate",
    },
    {
      Header: "Completion Date",
      accessor: "completionDate",
    },
    {
      Header: "Invoice Date",
      accessor: "invoiceDate",
    },
    {
      Header: "Billing Type",
      accessor: "billingType",
    },
    {
      Header: "Billing Rate",
      accessor: "billingRate",
    },
    {
      Header: "Set Up (Mins)",
      accessor: "setUpMins",
    },
    {
      Header: "Management (Mins)",
      accessor: "managementMins",
    },
    {
      Header: "Clear Down (Mins)",
      accessor: "clearDownMins",
    },
    {
      Header: "# of Completed Units",
      accessor: "completedUnits",
    },
    {
      Header: "Actual Second/Kit",
      accessor: "actualSecondPerKit",
    },
    {
      Header: "Estimated Secs/Kit",
      accessor: "estimatedSecsPerKit",
    },
    {
      Header: "Actual vs. Estimated Second/Kit Difference",
      accessor: "actualVsEstimatedSecDifference",
    },
    {
      Header: "Material Used",
      accessor: "materialUsed",
    },
    {
      Header: "Material Cost",
      accessor: "materialCost",
    },
    {
      Header: "Men Required",
      accessor: "menRequired",
    },
    {
      Header: "Estimated Man Hours",
      accessor: "estimatedManHours",
    },
    {
      Header: "Actual Man Hours",
      accessor: "actualManHours",
    },
    {
      Header: "Actual vs. Estimated Hours Difference",
      accessor: "actualVsEstimatedHoursDifference",
    },
    {
      Header: "Job Cost",
      accessor: "jobCost",
    },
    {
      Header: "Invoiced Amount",
      accessor: "invoicedAmount",
    },
    {
      Header: "Job Profit",
      accessor: "jobProfit",
    },
    {
      Header: "Margin",
      accessor: "margin",
    },
    {
      Header: "View",
      accessor: "id", // Assuming "id" is the property containing the record's unique identifier
      Cell: ({ row }) => (
        <Link
          to={`/support/view-job-profit/${row.values.id}`} // Change this route to match your actual route
          color="green.500"
        >
          View
        </Link>
      ),
    },
  ];

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [invoicedFilter, setInvoicedFilter] = useState("Yes");
  const [billableFilter, setBillableFilter] = useState("Yes");
  const [jobVoidFilter, setJobVoidFilter] = useState("No");
  const [completeFilter, setCompleteFilter] = useState("Yes");
  const [jobsCreatedOnFilter, setJobsCreatedOnFilter] = useState("");
  const [jobsInvoicedOnFilter, setJobsInvoicedOnFilter] = useState("");

  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }

    const paramsArray = queryString.split("&");
    const params = {};

    paramsArray.forEach((param) => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });

    return params;
  };

  const windowUrl = window.location.href;

  const queryParams = getQueryParams(windowUrl);
  const warehouseParam = queryParams["warehouse"];
  const clientParam = queryParams["client"];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const fetchDataForPage = async (
    page,
    pageSize,
    searchQuery,
    invoicedFilter,
    billableFilter,
    jobVoidFilter,
    completeFilter,
    jobsCreatedOnFilter,
    jobsInvoicedOnFilter
  ) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let parsedJobsCreatedOnFilter = "";
      let parsedJobsInvoicedOnFilter = "";
      if (jobsCreatedOnFilter) {
        parsedJobsCreatedOnFilter = format(jobsCreatedOnFilter, "MM-dd-yyyy");
      }
      if (jobsInvoicedOnFilter) {
        parsedJobsInvoicedOnFilter = format(jobsInvoicedOnFilter, "MM-dd-yyyy");
      }
      const response = await axios.get(
        `${constants.apiUrl}/support/profitablity-list`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            warehouse: warehouseParam,
            client: clientParam,
            search: searchQuery, // Add the search query to the params
            invoicedFilter,
            billableFilter,
            jobVoidFilter,
            completeFilter,
            jobsCreatedOnFilter: parsedJobsCreatedOnFilter,
            jobsInvoicedOnFilter: parsedJobsInvoicedOnFilter,
          },
        }
      );

      const data = response.data.jobListData.map((item) => ({
        ...item,
        id: item.id, // Assuming "id" is the property containing the unique identifier
      }));
      setTableData(data);
      setTotalPages(response.data.totalPages);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    } finally {
      setIsDataLoading(false);
    }
  };

  // Fetch data for the initial page

  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForPage(
        currentPage,
        pageSize,
        searchQuery,
        invoicedFilter,
        billableFilter,
        jobVoidFilter,
        completeFilter,
        jobsCreatedOnFilter,
        jobsInvoicedOnFilter
      );
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(
        currentPage,
        pageSize,
        searchQuery,
        invoicedFilter,
        billableFilter,
        jobVoidFilter,
        completeFilter,
        jobsCreatedOnFilter,
        jobsInvoicedOnFilter
      );
    }
  }, [
    currentPage,
    pageSize,
    searchQuery,
    invoicedFilter,
    billableFilter,
    jobVoidFilter,
    completeFilter,
    jobsCreatedOnFilter,
    jobsInvoicedOnFilter,
  ]);

  const textColor = "gray.900";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
      manualPagination: true,
      pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const exportToCSV = () => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let parsedJobsCreatedOnFilter = "";
      let parsedJobsInvoicedOnFilter = "";
      if (jobsCreatedOnFilter) {
        parsedJobsCreatedOnFilter = format(jobsCreatedOnFilter, "MM-dd-yyyy");
      }
      if (jobsInvoicedOnFilter) {
        parsedJobsInvoicedOnFilter = format(jobsInvoicedOnFilter, "MM-dd-yyyy");
      }

      axios
        .get(`${constants.apiUrl}/support/profitablity-list`, {
          headers,
          withCredentials: true,
          params: {
            // page: page,
            // pageSize: pageSize,
            warehouse: warehouseParam,
            client: clientParam,
            search: searchQuery,
            invoicedFilter,
            billableFilter,
            jobVoidFilter,
            completeFilter,
            jobsCreatedOnFilter: parsedJobsCreatedOnFilter,
            jobsInvoicedOnFilter: parsedJobsInvoicedOnFilter,
            exportCSV: true,
          },
        })
        .then((response) => {
          console.log(response.data);
          const data = response.data.jobListData.map((item) => ({
            ...item,
            id: item.id,
          }));

          const separator = ",";
          const csvContent =
            columnsData.map((column) => column.Header).join(separator) +
            "\n" +
            data
              .map((row) =>
                columnsData
                  .map((column) => {
                    let cell = row[column.accessor];
                    if (cell === null || cell === undefined) {
                      cell = "";
                    } else if (
                      typeof cell === "object" &&
                      cell.hasOwnProperty("value")
                    ) {
                      cell = cell.value;
                    }
                    cell = cell.toString().replace(/"/g, '""');
                    cell = cell.toString().replace(/€/g, "");
                    if (
                      cell.includes(separator) ||
                      cell.includes("\n") ||
                      cell.includes('"')
                    ) {
                      cell = `"${cell}"`;
                    }
                    return cell;
                  })
                  .join(separator)
              )
              .join("\n");

          const csvBlob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
          });
          const csvURL = window.URL.createObjectURL(csvBlob);

          // Generate file name
        const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
        let fileName;
        if (clientParam) {
          fileName = `${clientParam}-${timestamp}.csv`;
        } else if (clientParam && warehouseParam) {
          fileName = `${clientParam}-${warehouseParam}-${timestamp}.csv`;
        } else {
          fileName = `profitability-report-${timestamp}.csv`;
        }

          const tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", fileName);
          tempLink.click();

          resolve(); // Resolve the Promise after successful export
        })
        .catch((error) => {
          console.error("Error exporting csv data:", error);
          reject(error); // Reject the Promise if there's an error
        });
    });
  };

  // Function to handle button click event
  const handleExportButtonClick = () => {
    exportToCSV()
      .then(() => {
        console.log("CSV export completed successfully");
      })
      .catch((error) => {
        console.error("CSV export failed:", error);
      });
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex alignItems="left" mb={4}>
            {/* Add more filters here */}
            <Select
              value={invoicedFilter}
              onChange={(e) => setInvoicedFilter(e.target.value)}
              variant="outline"
              color={textColor}
              maxWidth="150px"
              ml="10px"
            >
              <option value="Yes">Invoiced: Yes</option>
              <option value="No">Invoiced: No</option>
              <option value="">Invoiced: Any</option>
            </Select>

            <Select
              value={billableFilter}
              onChange={(e) => setBillableFilter(e.target.value)}
              variant="outline"
              color={textColor}
              maxWidth="150px"
              ml="10px"
            >
              <option value="Yes">Billable: Yes</option>
              <option value="No">Billable: No</option>
              <option value="">Billable: Any</option>
            </Select>

            <Select
              value={jobVoidFilter}
              onChange={(e) => setJobVoidFilter(e.target.value)}
              variant="outline"
              color={textColor}
              maxWidth="150px"
              ml="10px"
            >
              <option value="Yes">Job Void: Yes</option>
              <option value="No">Job Void: No</option>
              <option value="">Job Void: Any</option>
            </Select>

            <Select
              value={completeFilter}
              onChange={(e) => setCompleteFilter(e.target.value)}
              variant="outline"
              color={textColor}
              maxWidth="160px"
              ml="10px"
            >
              <option value="Yes">Complete: Yes</option>
              <option value="No">Complete: No</option>
              <option value="">Complete: Any</option>
            </Select>

            <DatePicker
              selected={jobsCreatedOnFilter}
              onChange={(date) => setJobsCreatedOnFilter(date)}
              dateFormat="MM-dd-yyyy"
              placeholderText={`Jobs Created On/After`}
              isClearable
              customInput={
                <Input
                  variant="filled"
                  fontSize="sm"
                  size="md"
                  ml="10px"
                  placeholder={`Jobs Created On/After`}
                />
              }
            />

            <DatePicker
              selected={jobsInvoicedOnFilter}
              onChange={(date) => setJobsInvoicedOnFilter(date)}
              dateFormat="MM-dd-yyyy"
              placeholderText={`Jobs Invoiced On/After`}
              isClearable
              customInput={
                <Input
                  variant="filled"
                  fontSize="sm"
                  size="md"
                  ml="14px"
                  placeholder={`Jobs Invoiced On/After`}
                />
              }
            />

            <Icon
              as={FiDownload}
              onClick={handleExportButtonClick}
              variant="brand"
              w={"4em"}
              h={"1.5em"}
              marginTop={"5px"}
            >
              Export
            </Icon>
          </Flex>

          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              {clientParam
                ? `${
                    warehouseParam ? `${warehouseParam} - ` : ""
                  }${clientParam}`
                : warehouseParam}
            </Text>

            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
          </Flex>
          <Box overflowX="auto" maxWidth="100%">
            <Table
              {...getTableProps()}
              variant="simple"
              color="gray.500"
              mb="24px"
              style={{ overflowX: "auto" }}
            >
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        pe="10px"
                        key={index}
                        minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                        whiteSpace="normal"
                        wordBreak="break-word"
                      >
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <Icon as={FiArrowDown} />
                              ) : (
                                <Icon as={FiArrowUp} />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.length === 0 ? (
                  <Tr>
                    <Td colSpan={columnsData.length} textAlign="center">
                      No data available.
                    </Td>
                  </Tr>
                ) : (
                  page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, index) => {
                          let cellContent = cell.value;

                          if (cell.column.Header === "View") {
                            cellContent = (
                              <Link
                                to={`/support/view-job-profit?id=${cell.value}`} // Change this route to match your actual route
                                style={{
                                  display: "inline-block",
                                  padding: "6px 12px",
                                  backgroundColor: "green",
                                  color: "white",
                                  textDecoration: "none",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                View
                              </Link>
                            );
                          } else {
                            cellContent = (
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {cell.value}
                              </Text>
                            );
                          }

                          return (
                            <Td
                              {...cell.getCellProps()}
                              key={index}
                              fontSize={{ sm: "14px" }}
                              maxH="30px !important"
                              py="8px"
                              minW={{ sm: "150px", md: "200px", lg: "auto" }}
                              borderColor="transparent"
                            >
                              {cellContent}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
          </Box>
          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
